import Vue from 'vue'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Datatable from 'vue2-datatable-component'
// import store from '@/store'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Datatable)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/gallrombibl',
    name: 'GallRomBibl',
    component: () => import('../views/home/GallRomBibl.vue')
  },
  {
    path: '/docling',
    name: 'DocLing',
    component: () => import('../views/home/DocLing.vue')
  },
  {
    path: '/legame',
    name: 'LEGaMe',
    component: () => import('../views/home/LEGaMe.vue')
  },
  {
    path: '/scripta',
    name: 'Scripta',
    component: () => import('../views/home/Scripta.vue')
  },
  {
    path: '/about-project',
    name: 'GallRomDetails',
    component: () => import('../views/home/GallRomDetails.vue')
  },
  {
    path: '/publications',
    name: 'Publications',
    component: () => import('../views/Publications.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import('../views/dict/article/Articles.vue')
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import('../views/dict/article/Article.vue')
  },
  {
    path: '/lemmas',
    name: 'Lemmas',
    component: () => import('../views/dict/lemma/List.vue')
  },
  {
    path: '/lemma/:id',
    name: 'Lemma',
    component: () => import('../views/dict/lemma/Lemma.vue')
  },
  {
    path: '/lemmaSearch',
    name: 'LemmaSearch',
    component: () => import('../views/dict/lemma/LemmaSearch.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('../views/sem/category/List.vue')
  },
  {
    path: '/category/:id',
    name: 'CategoryDetails',
    component: () => import('../views/sem/category/Category.vue')
  },
  {
    path: '/senses',
    name: 'Senses',
    component: () => import('../views/sem/sense/List.vue')
  },
  {
    path: '/sense/:id',
    name: 'Sense',
    component: () => import('../views/sem/sense/Sense.vue')
  },
  {
    path: '/protospellings',
    name: 'Protospellings',
    component: () => import('../views/dict/protospelling/List.vue')
  },
  {
    path: '/protospelling/:id',
    name: 'ProtospellingDetails',
    component: () => import('../views/dict/protospelling/Protospelling.vue')
  },
  {
    path: '/references',
    name: 'References',
    component: () => import('../views/dict/reference/List.vue')
  },
  {
    path: '/reference/:id',
    name: 'ReferenceDetails',
    component: () => import('../views/dict/reference/Reference.vue')
  },
  {
    path: '/occurrences/internal',
    name: 'OccurrenceListInternal',
    component: () => import('../views/dict/occurrence/ListInternal.vue')
  },
  {
    path: '/occurrences/external',
    name: 'OccurrenceListExternal',
    component: () => import('../views/dict/occurrence/ListExternal.vue')
  },
  {
    path: '/occurrences/container',
    name: 'OccurrenceListExternalContainer',
    component: () => import('../views/dict/occurrence/ListExternalContainer.vue')
  },
  {
    path: '/occurrence/:id',
    name: 'OccurrenceDetails',
    component: () => import('../views/dict/occurrence/Occurrence.vue')
  },
  {
    path: '/texts',
    name: 'Texts',
    component: () => import('../views/bib/text/Texts.vue')
  },
  {
    path: '/text/:slug/:id',
    name: 'TextDetails',
    component: () => import('../views/bib/text/TextDetails.vue')
  },
  {
    path: '/metatexts',
    name: 'Metatexts',
    component: () => import('../views/bib/metatext/Metatexts.vue')
  },
  {
    path: '/metatext/:id',
    name: 'MetatextDetails',
    component: () => import('../views/bib/metatext/MetatextDetails.vue')
  },
  {
    path: '/writingtypes',
    name: 'Writingtypes',
    component: () => import('../views/bib/writingtype/Writingtypes.vue')
  },
  {
    path: '/writingtype/:id',
    name: 'WritingtypeDetails',
    component: () => import('../views/bib/writingtype/WritingtypeDetails.vue')
  },
  {
    path: '/scriptoria',
    name: 'Scriptoria',
    component: () => import('../views/bib/scriptorium/Scriptoria.vue')
  },
  {
    path: '/scriptorium/:id',
    name: 'ScriptoriumDetails',
    component: () => import('../views/bib/scriptorium/ScriptoriumDetails.vue')
  },
  {
    path: '/locations',
    name: 'Locations',
    component: () => import('../views/main/location/Locations.vue')
  },
  {
    path: '/scriptae',
    name: 'Scriptae',
    component: () => import('../views/main/scripta/Scriptae.vue')
  },
  {
    path: '/scripta/:id',
    name: 'ScriptaDetails',
    component: () => import('../views/main/scripta/ScriptaDetails.vue')
  },
  {
    path: '/personhists',
    name: 'Personhists',
    component: () => import('../views/bib/personhist/Personhists.vue')
  },
  {
    path: '/personhist/:id',
    name: 'PersonhistDetails',
    component: () => import('../views/bib/personhist/PersonhistDetails.vue')
  },
  {
    path: '/authors',
    name: 'Authors',
    component: () => import('../views/bib/author/Authors.vue')
  },
  {
    path: '/author/:id',
    name: 'AuthorDetails',
    component: () => import('../views/bib/author/AuthorDetails.vue')
  },
  {
    path: '/annotators',
    name: 'Annotators',
    component: () => import('../views/bib/annotator/Annotators.vue')
  },
  {
    path: '/annotator/:id',
    name: 'AnnotatorDetails',
    component: () => import('../views/bib/annotator/AnnotatorDetails.vue')
  },
  {
    path: '/manuscripts',
    name: 'Manuscripts',
    component: () => import('../views/bib/manuscript/Manuscripts.vue')
  },
  {
    path: '/manuscript/:slug/:id',
    name: 'ManuscriptDetails',
    component: () => import('../views/bib/manuscript/ManuscriptDetails.vue')
  },
  {
    path: '/historicalAuthors',
    name: 'HistoricalAuthors',
    component: () => import('../views/bib/author_hist/HistoricalAuthors.vue')
  },
  {
    path: '/historicalAuthor/:slug/:id',
    name: 'HistoricalAuthorDetails',
    component: () => import('../views/bib/author_hist/HistoricalAuthorDetails.vue')
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import('../views/bib/review/Reviews.vue')
  },
  {
    path: '/review/:id',
    name: 'ReviewDetails',
    component: () => import('../views/bib/review/ReviewDetails.vue')
  },
  {
    path: '/editions',
    name: 'Editions',
    component: () => import('../views/bib/edition/Editions.vue')
  },
  {
    path: '/edition/:slug/:id',
    name: 'EditionDetails',
    component: () => import('../views/bib/edition/EditionDetails.vue')
  },
  {
    path: '/studies',
    name: 'Studies',
    component: () => import('../views/bib/study/Studies.vue')
  },
  {
    path: '/study/:slug/:id',
    name: 'StudyDetails',
    component: () => import('../views/bib/study/StudyDetails.vue')
  },
  {
    path: '/searchsigleentities',
    name: 'SigleEntities',
    component: () => import('../views/bib/sigleentity/List.vue')
  },
  {
    path: '/searchsigleentity/:sigleSource',
    name: 'SigleEntityDetails',
    component: () => import('../views/bib/sigleentity/Details.vue')
  },
  {
    path: '/documentManagementSystem',
    name: 'DocumentManagementSystem',
    component: () => import('../views/doc/DocumentManagementSystem.vue')
  },
  {
    path: '/hccStats',
    name: 'HCCStats',
    component: () => import('../views/doc/HCCStats.vue')
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/doc/List.vue')
  },
  {
    path: '/document/:id',
    name: 'DocumentDetails',
    component: () => import('../views/doc/Details.vue')
  },

  {
    path: '/issuephons',
    name: 'IssuePhons',
    component: () => import('../views/scripta/issue_phon/IssuePhons.vue')
  },
  {
    path: '/issuephon/:id',
    name: 'IssuePhonDetails',
    component: () => import('../views/scripta/issue_phon/IssuePhonDetails.vue')
  },
  {
    path: '/issuetypes',
    name: 'IssueTypes',
    component: () => import('../views/scripta/issue_type/IssueTypes.vue')
  },
  {
    path: '/issuetype/:id',
    name: 'IssueTypeDetails',
    component: () => import('../views/scripta/issue_type/IssueTypeDetails.vue')
  },
  {
    path: '/issuevars',
    name: 'IssueVars',
    component: () => import('../views/scripta/issue_var/IssueVars.vue')
  },
  {
    path: '/issuevar/:id',
    name: 'IssueVarDetails',
    component: () => import('../views/scripta/issue_var/IssueVarDetails.vue')
  },
  {
    path: '/baseetyms',
    name: 'BaseEtyms',
    component: () => import('../views/scripta/base_etym/BaseEtyms.vue')
  },
  {
    path: '/baseetym/:id',
    name: 'BaseEtymDetails',
    component: () => import('../views/scripta/base_etym/BaseEtymDetails.vue')
  },
  {
    path: '/baseetymvars',
    name: 'BaseEtymVars',
    component: () => import('../views/scripta/base_etym_var/BaseEtymVars.vue')
  },
  {
    path: '/baseetymvar/:id',
    name: 'BaseEtymVarDetails',
    component: () => import('../views/scripta/base_etym_var/BaseEtymVarDetails.vue')
  },
  {
    path: '/baseindivs',
    name: 'BaseIndivs',
    component: () => import('../views/scripta/base_indiv/BaseIndivs.vue')
  },
  {
    path: '/baseindiv/:id',
    name: 'BaseIndivDetails',
    component: () => import('../views/scripta/base_indiv/BaseIndivDetails.vue')
  },
  {
    path: '/basespecials',
    name: 'BaseSpecials',
    component: () => import('../views/scripta/base_special/BaseSpecials.vue')
  },
  {
    path: '/basespecial/:id',
    name: 'BaseSpecialDetails',
    component: () => import('../views/scripta/base_special/BaseSpecialDetails.vue')
  },
  {
    path: '/phonolenvs',
    name: 'PhonolEnvs',
    component: () => import('../views/scripta/phonol_env/PhonolEnvs.vue')
  },
  {
    path: '/phonolenv/:id',
    name: 'PhonolEnvDetails',
    component: () => import('../views/scripta/phonol_env/PhonolEnvDetails.vue')
  },

  {
    path: '/heads',
    name: 'Heads',
    component: () => import('../views/scripta/head/Heads.vue')
  },
  {
    path: '/head/:id',
    name: 'HeadDetails',
    component: () => import('../views/scripta/head/HeadDetails.vue')
  },
  {
    path: '/hcc/:id',
    name: 'HCCDetails',
    component: () => import('../views/scripta/hcc/HCCDetails.vue')
  },
  {
    path: '/evolutions',
    name: 'Evolutions',
    component: () => import('../views/scripta/evolution/Evolutions.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/main/map/Map.vue')
  },

]

const router = new VueRouter({
  routes
})

// Handle session expired
router.onError((error) => {
  const pattern = /Loading chunk (.*) failed/g
  const isChunkLoadFailed = error.message.match(pattern)

  if (isChunkLoadFailed) {
    location.reload()
  } else {
    throw error
  }
})

export default router
