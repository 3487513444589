import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  scripta: null,
  scriptas: []
}

const mutations = {
  async setScripta(state, scripta){
    if (!config.editable) {
      scripta = await exportConvert.convert("scripta.details", scripta)
    }
    state.scripta = scripta
  },
  setScriptas(state, scriptas){
    state.scriptas = scriptas
  }
}

const getters = {
}

const actions = {
  loadScripta({ commit }, scriptaId){
    let url = `${API_URL}/scripta/${scriptaId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/scripta/${scriptaId.slice(0, 2)}/${scriptaId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(scripta => {
        commit('setScripta', scripta)
      })
  },
  searchScriptas({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/scriptas/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(scriptas => {
        commit('setScriptas', scriptas)
      })
  },
  saveScripta(_, scripta){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/scripta/${scripta.id}`, scripta)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createScripta(_, scripta){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/scripta`, scripta)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Scripta created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteScripta(_, scripta){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/scripta/${scripta.id}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  mergeScriptas(_, { mainScriptaId, scriptaIds }){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/scriptas/merge`, { mainScriptaId, scriptaIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
