import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  head: null,
  heads: [],
}

const mutations = {
  setHead(state, head){
    state.head = head
  },
  setHeads(state, heads){
    state.heads = heads
  },
}

const getters = {
}

const actions = {
  loadHead({ commit }, headId){
    axios
      .get(`${API_URL}/head/${headId}`)
      .then(r => r.data)
      .then(head => {
        commit('setHead', head)
      })
  },
  searchHeads({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/heads/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(heads => {
        commit('setHeads', heads)
      })
  },
  saveHead(_, head){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/head/${head.id}`, head)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createHead(_, head){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/head`, head)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Head created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteHead(_, head){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/head/${head.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Head deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  loadHeadTaggingsTypes(_, head_id) {
      return new Promise((resolve) => {
      axios
        .get(`${API_URL}/head_taggingstypes/${head_id}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  loadHeadTaggingsAbsoluteOccs(_, head_id) {
      return new Promise((resolve) => {
      axios
        .get(`${API_URL}/head_taggingsabsoluteoccs/${head_id}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
