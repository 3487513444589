import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide
let MAIN_URL = API_URL
let SUFFIX = ''
if (!config.editable) {
  MAIN_URL = API_URL_CLIENTSIDE
  SUFFIX = '.json'
}

const state = {
  manuscript: null,
  manuscripts: []
}

const mutations = {
  async setManuscript(state, manuscript){
    if (!config.editable) {
      manuscript = await exportConvert.convert("manuscript.details", manuscript)
    }
    state.manuscript = manuscript
  },
  setManuscripts(state, manuscripts){
    state.manuscripts = manuscripts
  }
}

const getters = {
}

const actions = {
  loadManuscript({ commit }, manuscriptId){
    let url = `${MAIN_URL}/manuscript/${manuscriptId}${SUFFIX}`
    if (!config.editable) {
      url = `${MAIN_URL}/manuscript/${manuscriptId.slice(0, 2)}/${manuscriptId}${SUFFIX}`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(manuscript => {
        commit('setManuscript', manuscript)
      })
  },
  searchManuscripts({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/manuscripts/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(manuscripts => {
        commit('setManuscripts', manuscripts)
      })
  },
  saveManuscript(_, manuscript){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/manuscript/${manuscript.id}`, manuscript)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          // reject(error.response.data)
        })
    })
  },
  createManuscript(_, manuscript){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/manuscript`, manuscript)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Manuscript created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteManuscript(_, manuscript){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/manuscript/${manuscript.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Manuscript deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
