import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

// function parseJwt (token) {
//   var base64Url = token.split('.')[1];
//   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));

//   return JSON.parse(jsonPayload);
// }

// localStorage.setItem('userToken', JSON.stringify({
//   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6InRlc3QifQ.qkjUKTgT39lMsNJhOHdp0EZLFhGF6nY49KXNL4sTFgg",
//   username: "test"
// }))

// axios.defaults.headers.common['X-Remote-User'] = 'nrajovic@uzh.ch'
// axios.defaults.headers.common['X-Real-Name'] = 'Nikolina Rajovic'

// let user = null
// if (localStorage.getItem('userToken')){
//   user = JSON.parse(localStorage.getItem('userToken'))
//   axios.defaults.headers.common['X-Access-Token'] = user.token
// }

let userSettings = {}
if (localStorage.getItem('userSettings')){
  userSettings = JSON.parse(localStorage.getItem('userSettings'))
  // Bug when changed id from int to uuid, so we need to ignore old settings with ints
  if (userSettings.version != true){
    userSettings = {}
  }
}

let userSettingsPI = {}
if (localStorage.getItem('userSettingsPI')){
  userSettingsPI = JSON.parse(localStorage.getItem('userSettingsPI'))
  // Bug when changed id from int to uuid, so we need to ignore old settings with ints
  if (!('biblabels' in userSettingsPI)){
    userSettingsPI['biblabels'] = []
  }
  if (!('dictionaries' in userSettingsPI)){
    userSettingsPI['dictionaries'] = []
  }
}
// console.log("UPI", userSettingsPI)

const state = {
  // user: user,
  roles: [],
  settings: userSettings,
  settingsPI: userSettingsPI
}

const mutations = {
  setRoles(state, roles){
    state.roles = roles
  },
  commitUserSettings(state, settings){
    state.settings = settings
    localStorage.setItem('userSettings', JSON.stringify(settings))
  },
  cUpdateUserSettingsPI(state, data){
    state.settingsPI[data.property] = data.value
    localStorage.setItem('userSettingsPI', JSON.stringify(state.settingsPI))
  }
  // setUser(state, user){
  //   state.user = user
  //   localStorage.setItem('userToken', JSON.stringify(user))
  // },
  // clearUser(state){
  //   state.user = null
  // }
}

const getters = {
  // isLoggedIn: function(state){
  //   return state.user ? true : false
  // },
  // getUser: function(state){
  //   return state.user
  // },
  // getToken: function(state){
  //   return (state.user ? state.user.token : null)
  // }

  checkRole: (state) => (roleToCheck) => {
    let hasRole = false
    for (let role of state.roles){
      let regex = new RegExp(role)
      if (regex.test(roleToCheck)){
        hasRole = true
        break
      }
    }
    return hasRole
  }
}

const actions = {
  getRoles({ commit }){
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_URL}/user/roles`)
        .then(response => {
          commit('setRoles', response.data)
          resolve(response)
        })
        .catch(err => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          reject(err)
        })
    })
  },
  setUserSettings({ commit }, settings){
    commit('commitUserSettings', settings)
  },
  setUserSettingsPI({ commit }, data){
    commit('cUpdateUserSettingsPI', data)
  },
  // login({ commit }, loginData){
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(`${API_URL}/user/authenticate`, loginData)
  //       .then(response => {
  //         commit('setUser', response.data)
  //         axios.defaults.headers.common['X-Access-Token'] = response.data.token
  //         resolve(response)
  //       })
  //       .catch(err => {
  //         this.dispatch('addNotification', {
  //           type: 'danger',
  //           text: `Something went wrong`
  //         })
  //         reject(err)
  //       })
  //   })
  // },
  // authenticate({ commit }){
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(`${API_URL}/user/authenticate`)
  //       .then(response => {
  //         commit('setUser', response.data)
  //         axios.defaults.headers.common['X-Access-Token'] = response.data.token
  //         resolve(response)
  //       })
  //       .catch(err => {
  //         this.dispatch('addNotification', {
  //           type: 'danger',
  //           text: `Something went wrong`
  //         })
  //         reject(err)
  //       })
  //   })
  // },
  // logout({ commit }){
  //   commit('clearUser')
  //   axios.defaults.headers.common['X-Access-Token'] = null
  //   localStorage.removeItem('userToken')
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
