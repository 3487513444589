import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  scriptorium: null,
  scriptoriums: [],
  scriptoriumsAll: [],
}

const mutations = {
  setScriptorium(state, scriptorium){
    state.scriptorium = scriptorium
  },
  setScriptoriumsAll(state, scriptoriums){
    state.scriptoriumsAll = scriptoriums
  },
  setScriptoriums(state, scriptoriums){
    state.scriptoriums = scriptoriums
  },
}

const getters = {
}

const actions = {
  loadScriptorium({ commit }, scriptoriumId){
    axios
      .get(`${API_URL}/scriptorium/${scriptoriumId}`)
      .then(r => r.data)
      .then(scriptorium => {
        commit('setScriptorium', scriptorium)
      })
  },
  loadScriptoriums({ commit }){
    axios
      .get(`${API_URL}/scriptorium/all`)
      .then(r => r.data)
      .then(scriptoriums => {
        commit('setScriptoriumsAll', scriptoriums)
      })
  },
  searchScriptoriums({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/scriptoriums/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(scriptoriums => {
        commit('setScriptoriums', scriptoriums)
      })
  },
  saveScriptorium(_, scriptorium){
    let url = `${API_URL}/scriptorium`
    if (scriptorium.id) {
      url += `/${scriptorium.id}`
    }
    return new Promise((resolve) => {
      axios
        .post(url, scriptorium)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createScriptorium(_, scriptorium){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/scriptorium`, scriptorium)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteScriptorium(_, scriptorium){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/scriptorium/${scriptorium.id}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  mergeScriptoria(_, { mainScriptoriumId, scriptoriumIds }){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/scriptoriums/merge`, { mainScriptoriumId, scriptoriumIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
