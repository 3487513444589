import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  phonolenv: null,
  phonolenvs: []
}

const mutations = {
  setPhonolenv(state, phonolenv){
    state.phonolenv = phonolenv
  },
  setPhonolenvs(state, phonolenvs){
    state.phonolenvs = phonolenvs
  }
}

const getters = {
}

const actions = {
  loadPhonolenv({ commit }, phonolenvId){
    axios
      .get(`${API_URL}/phonolenv/${phonolenvId}`)
      .then(r => r.data)
      .then(phonolenv => {
        commit('setPhonolenv', phonolenv)
      })
  },
  searchPhonolenvs({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/phonolenvs/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(phonolenvs => {
        commit('setPhonolenvs', phonolenvs)
      })
  },
  savePhonolenv(_, phonolenv){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/phonolenv/${phonolenv.id}`, phonolenv)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createPhonolenv(_, phonolenv){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/phonolenv`, phonolenv)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Phonol env created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deletePhonolenv(_, phonolenv){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/phonolenv/${phonolenv.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Phonol env deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
