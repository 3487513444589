import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  corpuses: [],
}

const mutations = {
  setCorpuses(state, corpuses){
    state.corpuses = corpuses.sort(function(a, b) {return a.edition.sigleLegam > b.edition.sigleLegam ? 1 : 0})
  },
}

const getters = {
}

const actions = {
  searchCorpuses({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/corpuses/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(corpuses => {
        commit('setCorpuses', corpuses)
      })
  },
  loadAllCorpuses({ commit }){
    let url = `${API_URL}/corpuses/all`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/corpora.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(corpuses => {
        commit('setCorpuses', corpuses)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
