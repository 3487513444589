import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'
import XmlBeautify from 'xml-beautify'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  // documentList: [],
  document: null
}

const prettifyXml = function(sourceXml) {
  return new XmlBeautify({ parser: DOMParser }).beautify(sourceXml);
}

const mutations = {
  // setDocumentList(state, payload) {
  //   state.documentList = payload;
  // },
  async setDocument(state, document) {
    if (!config.editable) {
      document = await exportConvert.convert("document.details", document)
      console.log("DOC", document)
    }
    state.document = document;
  }
}

const getters = {
}

const actions = {
  loadDocument({ commit }, data){
    if (config.editable) {
      let url = `${API_URL}/document/${data.documentId}`
      return new Promise((resolve) => {
        axios
          .post(url, data)
          .then(r => r.data)
          .then(document => {
            commit('setDocument', document)
            resolve(document)
          })
      })
    }
    else {
      let url = `${API_URL_CLIENTSIDE}/document/${data.documentId.slice(0, 2)}/${data.documentId}.json`
      return new Promise((resolve) => {
        axios
          .get(url)
          .then(r => r.data)
          .then(document => {
            commit('setDocument', document)
            resolve(document)
          })
      })
    }
  },
  getElementDetails(_, data){
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/document/${data.documentId}/element-content/${data.elementType}/${data.elementNum}`)
        .then(r => r.data)
        .then(content => {
          resolve(content)
        })
    })
  },
  getXML(_, documentId){
    let url = config.editable ? `${API_URL}/document/${documentId}/xml` : `${API_URL_CLIENTSIDE}/document/${documentId.slice(0, 2)}/${documentId}.xml`
    return new Promise((resolve) => {
      axios
        .get(url)
        .then(r => r.data)
        .then(content => {
          resolve(prettifyXml(content))
        })
    })
  },
  getPDF(_, documentId){
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/document/${documentId}/pdf`)
        .then(r => r.data)
        .then(content => {
          resolve(content)
        })
    })
  },
  lockDocument(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/document/${data.documentId}/lock`, data)
        .then(r => r.data)
        .then(content => {
          resolve(content)
        })
    })
  },
  saveDocument(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/document/${data.document.id}/save`, data)
        .then(response => {
          // this.dispatch('addNotification', {
          //   type: 'success',
          //   text: `Document saved`
          // })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteDocument(_, document){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/document/${document.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Document deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
}

export default {
    state,
    getters,
    actions,
    mutations
}
