const Utils = {
  getDateLocationAndFormOfOccurrence: (occurrence) => {
    let retDate = null
    let retLocation = null
    let retForm = null
    let retEditionSiglum = null
    let retPosition = null
    if (occurrence.externalEvidence){
      retForm = occurrence.externalEvidence.surfaceForm
      retLocation = occurrence.externalEvidence.calcTextLocation
      retDate = occurrence.externalEvidence.calcDateNumText

      if (occurrence.externalEvidence.edition){
        retEditionSiglum = occurrence.externalEvidence.edition.sigleLegam
      }

      retPosition = occurrence.externalEvidence.position

      if (retDate == null){
        retDate = occurrence.externalEvidence.calcDateSpecText
      }
    }
    else if (occurrence.token){
      retForm = occurrence.token.form
      retPosition = occurrence.token.division
      if (occurrence.token.document){
        retDate = occurrence.token.document.calcDateNumText
        retLocation = occurrence.token.document.calcTextLocation
        if (retDate == null && occurrence.token.document.manuscript){
          retDate = occurrence.token.document.manuscript.dateNum
        }
        if (occurrence.token.document.edition){
          retEditionSiglum = occurrence.token.document.edition.sigleLegam
        }
        if (retLocation == null
            && occurrence.token.document.manuscript
            && occurrence.token.document.manuscript
            && occurrence.token.document.manuscript.localization
        ){
          retLocation = occurrence.token.document.manuscript.localization.display
        }
      }
    }
    return [retDate, retLocation, retForm, retEditionSiglum, retPosition]
  },
  romanize(num) {
    if (isNaN(num))
      return NaN;
    var digits = String(+num).split(""),
      key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
              "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
              "","I","II","III","IV","V","VI","VII","VIII","IX"],
      roman = "",
      i = 3;
    while (i--)
      roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    let retval = Array(+digits.join("") + 1).join("M") + roman
    retval = retval.toLowerCase()
    return retval;
  },
  letterNumeration(value){
    let retval = [];
    while (value > 0) {
      let rem = value % 26;
      if (rem == 0) {
        retval.push("z");
        value = Math.floor(value / 26) - 1;
      }
      else
      {
        retval.push(String.fromCharCode((rem - 1) + 'a'.charCodeAt(0)));
        value = Math.floor(value / 26);
      }
    }
    return retval.reverse().join("")
  },
  uuidv4(){
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  },
  normalizeString(value){
    return value.normalize("NFD").replace(/\p{Diacritic}/gu, "")
  },
  sortHelper(a, b){
    if (typeof a === 'string' || a instanceof String){
      a = Utils.normalizeString(a).replace(/[^a-zA-Z\s0-9]/g, "").trim()
    }
    if (typeof b === 'string' || b instanceof String){
      b = Utils.normalizeString(b).replace(/[^a-zA-Z\s0-9]/g, "").trim()
    }
    return Intl.Collator().compare(a, b);
    // let retVal = 0
    // if (a && b){
    //   retVal = a.toString().localeCompare(b.toString())
    // }
    // else if (a){
    //   retVal = -1
    // }
    // else if (b){
    //   retVal = 1
    // }
    // return retVal
  },
  // sortOccurrencesReferencesList(data, firstSort = 'form'){
  //   data.sort(function (a, b){
  //     let dateA = null
  //     let locationA = null
  //     let formA = null
  //     let siglumA = null
  //     let positionA = null
  //     if (a._occurrenceType == "occurrence" || a._occurrenceType == "containeroccurrence"){
  //       [dateA, locationA, formA, siglumA, positionA] = Utils.getDateLocationAndFormOfOccurrence(a)
  //     }
  //     else {
  //       [dateA, formA, siglumA] = Utils.getDateAndFormOfReference(a)
  //     }
  //     let dateB = null
  //     let locationB = null
  //     let formB = null
  //     let siglumB = null
  //     let positionB = null
  //     if (b._occurrenceType == "occurrence" || b._occurrenceType == "containeroccurrence"){
  //       [dateB, locationB, formB, siglumB, positionB] = Utils.getDateLocationAndFormOfOccurrence(b)
  //     }
  //     else {
  //       [dateB, formB, siglumB] = Utils.getDateAndFormOfReference(b)
  //     }

  //     let retVal = 0
  //     if (firstSort == "form"){
  //       retVal = Utils.sortHelper(formA, formB) || Utils.sortHelper(dateA, dateB) ||  Utils.sortHelper(siglumA, siglumB) || Utils.sortHelper(positionA, positionB) || Utils.sortHelper(locationA, locationB)
  //     }
  //     else {
  //       retVal = Utils.sortHelper(dateA, dateB) || Utils.sortHelper(formA, formB) ||  Utils.sortHelper(siglumA, siglumB) || Utils.sortHelper(positionA, positionB) || Utils.sortHelper(locationA, locationB)
  //     }
  //     return retVal
  //   })
  //   return data
  // },
  sortOccurrencesReferences(data, firstSort = 'form'){
    if (data){
      data.sort(function (a, b){
        let dateA = null
        let locationA = null
        let formA = null
        let siglumA = null
        let positionA = null
        if (a._occurrenceType == "occurrence" || a._occurrenceType == "containeroccurrence"){
          [dateA, locationA, formA, siglumA, positionA] = Utils.getDateLocationAndFormOfOccurrence(a)
        }
        else {
          [dateA, formA, siglumA] = Utils.getDateAndFormOfReference(a)
        }
        let dateB = null
        let locationB = null
        let formB = null
        let siglumB = null
        let positionB = null
        if (b._occurrenceType == "occurrence" || b._occurrenceType == "containeroccurrence"){
          [dateB, locationB, formB, siglumB, positionB] = Utils.getDateLocationAndFormOfOccurrence(b)
        }
        else {
          [dateB, formB, siglumB] = Utils.getDateAndFormOfReference(b)
        }

        let retVal = 0
        if (firstSort == "form"){
          retVal = Utils.sortHelper(formA, formB) || Utils.sortHelper(dateA, dateB) ||  Utils.sortHelper(siglumA, siglumB) || Utils.sortHelper(positionA, positionB) || Utils.sortHelper(locationA, locationB)
        }
        else {
          retVal = Utils.sortHelper(dateA, dateB) || Utils.sortHelper(formA, formB) ||  Utils.sortHelper(siglumA, siglumB) || Utils.sortHelper(positionA, positionB) || Utils.sortHelper(locationA, locationB)
        }
        return retVal
      })
    }
    return data
  },
  _getSurfaceForm(object){
    let retVal = ""
    if (object._occurrenceType == "occurrence" || object._occurrenceType == "containeroccurrence"){
      if (object.externalEvidence){
        retVal = object.externalEvidence.surfaceForm
      }
      else if (object.token){
        retVal = object.token.form
      }
    }
    else if (object._occurrenceType == "reference"){
      retVal = object.surfaceForm
    }
    return retVal
  },
  // sortOccurrences(occurrences){
  //   // Order by date, then by place and then alphabetically
  //   occurrences.sort(function (a, b){
  //     let [dateA, locationA, formA, editionSiglumA, positionA] = Utils.getDateLocationAndFormOfOccurrence(a)
  //     let [dateB, locationB, formB, editionSiglumB, positionB] = Utils.getDateLocationAndFormOfOccurrence(b)

  //     return Utils.sortHelper(dateA, dateB) || Utils.sortHelper(positionA, positionB) || Utils.sortHelper(formA, formB) || Utils.sortHelper(editionSiglumA, editionSiglumB) ||  Utils.sortHelper(locationA, locationB);
  //   })
  //   return occurrences
  // },
  getDateAndFormOfReference: (reference) => {
    return [reference.dateNum, reference.surfaceForm, reference.siglum]
  },
  // sortReferences(references){
  //   // Order by date, then by place and then alphabetically
  //   references.sort(function (a, b){
  //     let [dateA, formA, siglumA] = Utils.getDateAndFormOfReference(a)
  //     let [dateB, formB, siglumB] = Utils.getDateAndFormOfReference(b)

  //     let retVal = 0
  //     if (retVal == 0){
  //       retVal = Utils.sortHelper(dateA, dateB)
  //     }
  //     if (retVal == 0){
  //       retVal = Utils.sortHelper(formA, formB)
  //     }
  //     if (retVal == 0){
  //       retVal = Utils.sortHelper(siglumA, siglumB)
  //     }

  //     return retVal
  //   })
  //   return references
  // }
  scriptoriumString(scriptorium) {
    if (scriptorium.confidenceScriptorium) {
      let label = null
      switch (scriptorium.confidenceScriptorium) {
        case 'certain': {
          label = '<tt><font style="background-color:#97f4ca;color:white;bold";>!</font></tt>'
          break
        }
        case 'uncertain': {
          label = '<tt><font style="background-color:#f4a097;color:white;bold";>?</font></tt>'
          break
        }
        default: {
          break
        }
      }
      return label ? `${scriptorium.abbreviation} ${label}` : scriptorium.abbreviation
    }
    else {
      return scriptorium.abbreviation
    }
  },
  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
}

export default Utils
