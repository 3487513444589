import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  issuephon: null,
  issuephons: []
}

const mutations = {
  setIssuephon(state, issuephon){
    state.issuephon = issuephon
  },
  setIssuephons(state, issuephons){
    state.issuephons = issuephons
  }
}

const getters = {
}

const actions = {
  loadIssuephon({ commit }, issuephonId){
    axios
      .get(`${API_URL}/issuephon/${issuephonId}`)
      .then(r => r.data)
      .then(issuephon => {
        commit('setIssuephon', issuephon)
      })
  },
  searchIssuephons({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/issuephons/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(issuephons => {
        commit('setIssuephons', issuephons)
      })
  },
  saveIssuephon(_, issuephon){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/issuephon/${issuephon.id}`, issuephon)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createIssuephon(_, issuephon){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/issuephon`, issuephon)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Issue Phon created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteIssuephon(_, issuephon){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/issuephon/${issuephon.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Issue Phon deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
