import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  authors: [],
  author: null
}

const mutations = {
  async setAuthor(state, author){
    if (!config.editable) {
      author = await exportConvert.convert("author.details", author)
    }
    state.author = author
  },
  setAuthors(state, authors){
    state.authors = authors
  },
}

const getters = {
  // getArticle: (state) => (id) => state.articles.filter(article => article.id == id),
}

const actions = {
  searchAuthors({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/authors/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(authors => {
        commit('setAuthors', authors)
      })
  },
  loadAuthor({ commit }, id){
    let url = `${API_URL}/author/${id}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/author/${id.slice(0, 2)}/${id}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(author => {
        commit('setAuthor', author)
      })
  },
  saveAuthor(_, author){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/author/${author.id}`, author)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  mergeAuthors(_, { mainAuthorId, authorIds }){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/authors/merge`, { mainAuthorId, authorIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createAuthor(_, author){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/author`, author)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteAuthor(_, author){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/author/${author.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Author deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
