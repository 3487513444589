let config
if (process.env.NODE_ENV == 'production') {
  config = {
    apiUrl: '/api',
    appDomain: 'gallrom.linguistik.uzh.ch',
    apiUrlClientSide: 'https://gallrom.linguistik.uzh.ch/data',
    imagesUrl: '/legam_images',
    apiHeaders: {},
    sentryDSN: 'https://7ed838544c1e4519bf3978d420635180@sentry.linguistik.uzh.ch/2',
    editable: false,
    // editable: true,
  }
}
else if (process.env.NODE_ENV == 'test') {
  config = {
    apiUrl: '/api',
    appDomain: 'legamedittest.linguistik.uzh.ch',
    imagesUrl: '/legam_images',
    environment: 'test',
    apiHeaders: {},
    sentryDSN: 'https://7ed838544c1e4519bf3978d420635180@sentry.linguistik.uzh.ch/2',
    // editable: false,
    editable: true,
  }
}
else if (process.env.NODE_ENV == 'staging') {
  config = {
    apiUrl: '/api',
    appDomain: 'legameditnext.linguistik.uzh.ch',
    environment: 'staging',
    apiHeaders: {},
    sentryDSN: 'https://7ed838544c1e4519bf3978d420635180@sentry.linguistik.uzh.ch/2',
    // editable: false,
    editable: true,
  }
}
else {
  // development
  config = {
    apiUrl: 'http://localhost:5001',
    apiUrlClientSide: 'http://localhost:5002',
    imagesUrl: 'http://localhost:8000',
    appDomain: 'localhost',
    environment: 'development',
    apiHeaders: {
      "X-Remote-User": "igor.mustac@uzh.ch",
      "X-Display-Name": "Igor Mustac",
      "X-Home-Organization": "uzh.ch",
      "X-Schac-Home-Organization": "uzh.ch",
      "X-Given-Name": "Igor",
      "X-Surname": "Mustac",
      "X-Mail": "igor.mustac@uzh.ch",
    },
    sentryDSN: null,
    // editable: true,
    editable: false,
  }
}

export default config
