import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  link: null,
  links: []
}

const mutations = {
  setLink(state, link){
    state.link = link
  },
  setLinks(state, links){
    state.links = links
  }
}

const getters = {
}

const actions = {
  loadLink({ commit }, linkId){
    let url = `${API_URL}/link/${linkId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/link/${linkId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(link => {
        commit('setLink', link)
      })
  },
  searchLinks({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/links/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(links => {
        commit('setLinks', links)
      })
  },
  saveLink(_, link){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/link/${link.id}`, link)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Link saved`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          // reject(error.response.data)
        })
    })
  },
  createLink(_, link){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/link`, link)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Link created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteLink(_, link){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/link/${link.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Link deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
