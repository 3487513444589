import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  authorhist: null,
  authorhists: []
}

const mutations = {
  async setAuthorhist(state, authorhist){
    if (!config.editable) {
      authorhist = await exportConvert.convert("authorhist.details", authorhist)
    }
    state.authorhist = authorhist
  },
  setAuthorhists(state, authorhists){
    state.authorhists = authorhists
  }
}

const getters = {
}

const actions = {
  loadAuthorhist({ commit }, authorhistId){
    let url = `${API_URL}/authorhist/${authorhistId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/authorhist/${authorhistId.slice(0, 2)}/${authorhistId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(authorhist => {
        commit('setAuthorhist', authorhist)
      })
  },
  searchAuthorhists({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/authorhists/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(authorhists => {
        commit('setAuthorhists', authorhists)
      })
  },
  saveAuthorhist(_, authorhist){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/authorhist/${authorhist.id}`, authorhist)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createAuthorhist(_, authorhist){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/authorhist`, authorhist)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Authorhist created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteAuthorhist(_, authorhist){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/authorhist/${authorhist.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Authorhist deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
