import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  location: null,
  locations: [],
  allGeoLocations: []
}

const mutations = {
  setLocation(state, location){
    state.location = location
  },
  setLocations(state, locations){
    state.locations = locations
  },
  setGeoLocations(state, locations) {
    state.allGeoLocations = locations
  }
}

const getters = {
}

const actions = {
  loadLocation({ commit }, locationId){
    axios
      .get(`${API_URL}/location/${locationId}`)
      .then(r => r.data)
      .then(location => {
        commit('setLocation', location)
      })
  },
  searchLocations({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/locations/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(locations => {
        commit('setLocations', locations)
      })
  },
  allGeoLocations({ commit }){
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/locations/all_geolocations`)
        .then(r => r.data)
        .then(locations => {
          commit('setGeoLocations', locations)
          resolve()
        })
    })
  },
  saveLocation(_, location){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/location/${location.id}`, location)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createLocation(_, location){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/location`, location)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Location created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteLocation(_, location){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/location/${location.id}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  mergeLocations(_, { mainLocationId, locationIds }){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/locations/merge`, { mainLocationId, locationIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}

