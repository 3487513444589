import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import JQuery from 'jquery'
import Select2 from 'v-select2-component'
import Multiselect from 'vue-multiselect'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import config from '@/config.js'
import i18n from "./i18n";

window.$ = JQuery
window.jQuery = JQuery
Vue.component('Select2', Select2)
Vue.component('multiselect', Multiselect)
Vue.config.productionTip = false

if (config.sentryDSN) {
  Sentry.init({
    Vue,
    dsn: config.sentryDSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", config.appDomain, /^\//],
      }),
    ],
    environment: config.environment,
    tracesSampleRate: 1.0
  })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
