import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide
let MAIN_URL = API_URL
let SUFFIX = ''
if (!config.editable) {
  MAIN_URL = API_URL_CLIENTSIDE
  SUFFIX = '.json'
}

const state = {
  siglesource: null,
  siglesources: [],
  siglesourcesUnion: {}
}

const mutations = {
  setSiglesource(state, siglesource){
    state.siglesource = siglesource
  },
  setSiglesources(state, siglesources){
    state.siglesources = siglesources
  },
  setSiglesourcesUnion(state, siglesourcesUnion){
    state.siglesourcesUnion = siglesourcesUnion
  }
}

const getters = {
}

const actions = {
  loadSiglesource({ commit }, code){
    axios
      .get(`${MAIN_URL}/siglesource/${code}${SUFFIX}`)
      .then(r => r.data)
      .then(siglesource => {
        commit('setSiglesource', siglesource)
      })
  },
  loadSiglesources({ commit }){
    axios
      .get(`${API_URL}/siglesources/all`)
      .then(r => r.data)
      .then(siglesources => {
        commit('setSiglesources', siglesources)
      })
  },
  loadSiglesourcesUnion({ commit }){
    axios
      .get(`${API_URL}/siglesources/union`)
      .then(r => r.data)
      .then(siglesourcesUnion => {
        commit('setSiglesourcesUnion', siglesourcesUnion)
      })
  },
  saveSiglesource(_, siglesource){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/siglesource/${siglesource.code}`, siglesource)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  // createStudy(_, study){
  //   return new Promise((resolve) => {
  //     axios
  //       .post(`${API_URL}/study`, study)
  //       .then(response => {
  //         this.dispatch('addNotification', {
  //           type: 'success',
  //           text: `Study created`
  //         })
  //         resolve(response)
  //       })
  //       .catch(() => {
  //         this.dispatch('addNotification', {
  //           type: 'danger',
  //           text: `Something went wrong`
  //         })
  //       })
  //   })
  // },
  deleteSiglesource(_, siglesource){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/siglesource/${siglesource.code}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Sigle source deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
