import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  writingtype: null,
  writingtypes: []
}

const mutations = {
  async setWritingtype(state, writingtype){
    if (!config.editable) {
      writingtype = await exportConvert.convert("writingtype.details", writingtype)
    }
    state.writingtype = writingtype
  },
  setWritingtypes(state, writingtypes){
    state.writingtypes = writingtypes
  }
}

const getters = {
}

const actions = {
  loadWritingtype({ commit }, writingtypeId){
    let url = `${API_URL}/writingtype/${writingtypeId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/writingtype/${writingtypeId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(writingtype => {
        commit('setWritingtype', writingtype)
      })
  },
  searchWritingtypes({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/writingtypes/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(writingtypes => {
        commit('setWritingtypes', writingtypes)
      })
  },
  saveWritingtype(_, writingtype){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/writingtype/${writingtype.id}`, writingtype)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createWritingtype(_, writingtype){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/writingtype`, writingtype)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Writing type created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteWritingtype(_, writingtype){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/writingtype/${writingtype.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Writing type deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
