import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide
let MAIN_URL = API_URL
let SUFFIX = ''
if (!config.editable) {
  MAIN_URL = API_URL_CLIENTSIDE
  SUFFIX = '.json'
}

const state = {
  lemmas: [],
  lemma: null,
  selectedLemmas: []
}

const mutations = {
  setLemmas(state, lemmas){
    state.lemmas = lemmas
  },
  async setLemma(state, lemma){
    state.lemma = lemma
  },
  setLemmaSenses(state, lemma){
    state.lemma.senseTree = lemma.senseTree
    state.lemma.senses = lemma.senses
    state.lemma.sensesDictIndex = lemma.sensesDictIndex
  },
  setLemmaSpellings(state, lemma){
    state.lemma.protospellingTree = lemma.protospellingTree
    state.lemma.protospellings = lemma.protospellings
    state.lemma.protospellingsDictIndex = lemma.protospellingsDictIndex
  }
}

const getters = {
}

const actions = {
  loadLemma({ commit }, id){
    let url = `${MAIN_URL}/lemma/${id}${SUFFIX}`
    if (!config.editable) {
      url = `${MAIN_URL}/lemma/${id.slice(0,2)}/${id}.json`
    }
    return new Promise((resolve) => {
      axios
        .get(url)
        .then(r => r.data)
        .then(lemma => {
          if (!config.editable) {
            exportConvert.convert("lemma.details", lemma).then((lemma) => {
              let protospellings = {}
              lemma.protospellings.forEach((protospelling) => {
                protospellings[protospelling.id] = protospelling
              })
              lemma.protospellings = protospellings
              let senses = {}
              lemma.senses.forEach((sense) => {
                senses[sense.id] = sense
              })
              lemma.senses = senses
              commit('setLemma', lemma)
              resolve(lemma)
            })
          }
          else {
            commit('setLemma', lemma)
            resolve(lemma)
          }
        })
    })
  },
  loadSimpleLemma(_, id){
    let url = `${MAIN_URL}/lemma/${id}${SUFFIX}`
    if (!config.editable) {
      url = `${MAIN_URL}/lemma/${id.slice(0,2)}/${id}.json`
    }
    return new Promise((resolve) => {
      axios
        .get(url)
        .then(r => r.data)
        .then(lemma => {
          if (!config.editable) {
            exportConvert.convert("lemma", lemma).then((lemma) => {
              resolve(lemma)
            })
          }
          else {
            // commit('setLemma', lemma)
            resolve(lemma)
          }
        })
    })
  },
  loadLemmaSenses({ commit }, id){
    return new Promise((resolve) => {
      axios
        .get(`${MAIN_URL}/sense/${id}${SUFFIX}`)
        .then(r => r.data)
        .then(lemma => {
          commit('setLemmaSenses', lemma)
          resolve(lemma)
        })
    })
  },
  loadLemmaSpellings({ commit }, id){
    return new Promise((resolve) => {
      axios
        .get(`${MAIN_URL}/protospellings/${id}${SUFFIX}`)
        .then(r => r.data)
        .then(lemma => {
          commit('setLemmaSpellings', lemma)
          resolve(lemma)
        })
    })
  },
  loadLemmaTokens(_, id){
    let url = `${MAIN_URL}/lemma/${id}/tokens`
    if (!config.editable) {
      url = `${MAIN_URL}/lemma/${id.slice(0,2)}/tokens-${id}.json`
    }
    return new Promise((resolve) => {
      axios
        .get(url)
        .then(r => r.data)
        .then(tokens => {
          if (!config.editable) {
            exportConvert.convert("tokens", [tokens]).then((tokens) => {
              tokens['tokensDictIndex'] = {}
              tokens.tokens.forEach((token, index) => {
                tokens['tokensDictIndex'][token.id] = index
              })
              resolve(tokens)
            })
          }
          else {
            resolve(tokens)
          }
        })
    })
  },
  searchLemmas({ commit }, data){
    let withoutArticle = data.withoutArticle ? '&withoutArticle=1' : ''
    axios
      .get(`${API_URL}/lemmas/autocomplete?query=${data.query}${withoutArticle}`)
      .then(r => r.data)
      .then(lemmas => {
        commit('setLemmas', lemmas)
      })
  },
  fuzzySearchLemmata(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemmas/fuzzySearchLemmata`, data)
        .then(response => {
          resolve(response)
        })
    })
  },
  updateLemma({ commit }, lemma){
    commit('setLemma', lemma)
  },
  addProtospellingToLemma(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${MAIN_URL}/lemma/${data.lemmaId}/protospelling`, {
          protospellingId: data.protospellingId,
          lemmaProtospelling: data.lemmaProtospelling
        })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  addSenseToLemma(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${MAIN_URL}/lemma/${data.lemmaId}/sense`, {
          senseId: data.senseId,
          lemmaSense: data.lemmaSense
        })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  saveLemmaOccurrenceTree(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${MAIN_URL}/lemma/${data.lemmaId}/${data.objectType}/tree`, data.tree)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  mergeLemmas(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/${data.mainLemmaId}/merge`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createLemma(_, lemma){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma`, lemma)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Lemma created`
          })
          // commit()
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          // reject(error.response.data)
        })
    })
  },
  saveLemma(_, lemma){
    return new Promise((resolve) => {
      delete lemma.tokens
      delete lemma.references
      delete lemma.protospellings
      delete lemma.senses
      axios
        .post(`${API_URL}/lemma/${lemma.id}`, lemma)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Lemma saved`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          // reject(error.response.data)
        })
    })
  },
  saveLemmaTree(_, lemma){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/${lemma.id}/tree`, lemma)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Lemma saved`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          // reject(error.response.data)
        })
    })
  },
  deleteLemma(_, lemmaId){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/lemma/${lemmaId}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Lemma deleted`
          })
          // commit()
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  saveEtymology(_, data){
    let url
    if (data.etymology.id.toString().substr(0, 4) == '_new'){
      // New
      url = `${API_URL}/lemma/${data.lemmaId}/etymology`
    }
    else {
      // Update
      url = `${API_URL}/lemma/${data.lemmaId}/etymology/${data.etymology.id}`
    }
    return new Promise((resolve) => {
      axios
        .post(url, data.etymology)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Egymology saved`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteEtymology(_, data){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/lemma/${data.lemmaId}/etymology/${data.etymology.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Egymology deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  addOccurrenceToSPS(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/${data.lemmaId}/occurrence/add`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  addReferenceToSPS(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/${data.lemmaId}/reference/add`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteOccurrenceReference(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/${data.lemmaId}/occurrence/delete`, data)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Occurrence deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  moveOccurrencesReferenceToLemma(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/${data.lemmaId}/occurrences_references/move`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  changeOccurrenceReference(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/${data.lemmaId}/occurrence/change`, data)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Occurrence changed`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  saveProtospellingSense(_, data){
    let url = `${API_URL}/lemma/${data.lemmaId}/${data.objectType}/save`
    return new Promise((resolve) => {
      axios
        .post(url, data)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Data saved`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  saveLemmaOccurrence(_, data){
    let url = `${API_URL}/lemma/${data.lemmaId}/occurrence/${data.objectId}/save`
    return new Promise((resolve) => {
      axios
        .post(url, data)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Data saved`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
