<template>
  <div id="notifications">
    <b-alert
      :show="message.dismissCountDown"
      dismissible
      fade
      class="notification"
      :variant="message.type"
      v-for="(message, index) in messages" :key="index"
      @dismiss-count-down="countDownChanged($event, index)"
    >
      {{ message.text }}
      <small class="float-right">{{ message.dismissCountDown }}</small>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="message.dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
  </div>
</template>

<style scoped>
  #notifications {
    width: 100%;
    margin: auto;
    position: fixed;
    bottom: 1em;
    text-align: center;
    z-index: 100000;
  }

  .notification {
    width: 50%;
    margin: auto;
    text-align: left;
    margin-top: 7px;
  }

  small {
    opacity: 0.4;
    margin-top: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Notifications',
    data() {
      return {
        dismissSecs: 5,
        messages: []
      }
    },
    computed: {
      ...mapState({
        notifications: state => state.notification.notifications
      })
    },
    watch: {
      'notifications'(){
        let _notifications = this.notifications
        if (_notifications.length > 0){
          _notifications.forEach(element => {
            this.messages.push({...element, show: true, dismissCountDown: this.dismissSecs})
          })
          this.$store.dispatch('clearNotifications')
        }
      }
    },
    methods: {
      countDownChanged(dismissCountDown, index) {
        this.messages[index].dismissCountDown = dismissCountDown
      }
    }
  }
</script>
