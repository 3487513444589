import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  texts: [],
  text: null
}

const mutations = {
  setTexts(state, texts){
    state.texts = texts
  },
  async setText(state, text){
    if (!config.editable) {
      text = await exportConvert.convert("text.details", text)
    }
    state.text = text
  }
}

const getters = {
  // getArticle: (state) => (id) => state.articles.filter(article => article.id == id),
}

const actions = {
  searchTexts({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/texts/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(texts => {
        commit('setTexts', texts)
      })
  },
  loadText({ commit }, textId){
    let url = `${API_URL}/text/${textId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/text/${textId.slice(0, 2)}/${textId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(text => {
        commit('setText', text)
      })
  },
  saveText(_, text){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/text/${text.id}`, text)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  mergeTexts(_, { mainTextId, textIds }){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/texts/merge`, { mainTextId, textIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createText(_, text){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/text`, text)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteText(_, text){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/text/${text.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Text deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
