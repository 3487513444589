import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  types: [],
  allTypes: [],
  allTypesDocs: {},
  allLemmaTypes: {},
  setAllDocuments: {},
  context: [],
  tokensSettings: null,
  documentsPerCorpus: {},
  selectedTokensLemmas: [],
  PIDataLoaded: false,
  typesToLemma: {},
}

const mutations = {
  setTypes(state, types) {
    state.types = types;
  },
  setAllTypes(state, types) {
    console.log("TRRR", types[61416])
    state.allTypes = types;
  },
  setTypesDocs(state, data) {
    state.allTypesDocs = data;
  },
  setLemmaTypes(state, data) {
    let types = {}
    Object.keys(data).forEach(lemma => {
      data[lemma][1].forEach(tokenId => {
        types[tokenId] = lemma
        // if (tokenId == 61416) {
        //   // console.log("ASADAD", lemma)

        // }
      })
    })
    // console.log("ARAR", types)
    state.typesToLemma = types
    state.allLemmaTypes = data;
  },
  setAllDocuments(state, data) {
    let documentsPerCorpus = {}
    Object.keys(data).forEach(documentId => {
      if (!documentsPerCorpus[data[documentId][0]]) {
        documentsPerCorpus[data[documentId][0]] = []
      }
      documentsPerCorpus[data[documentId][0]].push(documentId)
    })
    state.documentsPerCorpus = documentsPerCorpus;
    state.allDocuments = data;
  },
  setContext(state, context) {
    state.context = context;
  },
}

const getters = {
}

const actions = {
  loadPIData({ commit }) {
    if (this.state.docediting.PIDataLoaded == false) {
      axios
        .get(`${API_URL_CLIENTSIDE}/token_search/types.json`)
        .then(r => r.data)
        .then(data => {
          commit('setAllTypes', data);
        })
      axios
        .get(`${API_URL_CLIENTSIDE}/token_search/types_docs_idxs.json`)
        .then(r => r.data)
        .then(data => {
          commit('setTypesDocs', data);
        })
      axios
        .get(`${API_URL_CLIENTSIDE}/token_search/lemma_types.json`)
        .then(r => r.data)
        .then(data => {
          commit('setLemmaTypes', data);
        })
      axios
        .get(`${API_URL_CLIENTSIDE}/token_search/documents.json`)
        .then(r => r.data)
        .then(data => {
          commit('setAllDocuments', data);
        })
        this.state.docediting.PIDataLoaded = true
    }
  },
  searchTypes({ commit }, params) {
    if (!config.editable) {
      let results = []
      if (params.searchBy == "lemma") {
        Object.keys(this.state.docediting.allLemmaTypes).forEach((lemma, index) => {
          if (lemma.match(params.queryRegex)) {
            let docsTypes = {}
            let article = this.state.docediting.allLemmaTypes[lemma][0]
            let resultString = `${lemma} ${article}`
            this.state.docediting.allLemmaTypes[lemma][1].forEach(typeIndex => {
              let newDocTypes = this.state.docediting.allTypesDocs[typeIndex]
              if (params.corpora.length > 0) {
                let filteredNewDocTypes = {}
                Object.keys(newDocTypes).forEach(docId => {
                  params.corpora.forEach(corpus => {
                    if (this.state.docediting.documentsPerCorpus[corpus].includes(docId)) {
                      filteredNewDocTypes[docId] = newDocTypes[docId]
                    }
                  })
                })
                newDocTypes = filteredNewDocTypes
              }
              docsTypes = {
                ...docsTypes,
                ...newDocTypes
              }
            })
            if (Object.keys(docsTypes).length > 0) {
              results.push({
                "form": resultString,
                "lemma_id": index,
                "document_id_token_ids": docsTypes
              })
            }
          }
        })
      }
      else {
        this.state.docediting.allTypes.forEach((type, index) => {
          if (type.match(params.queryRegex)) {
            let newDocTypes = this.state.docediting.allTypesDocs[index]
            if (params.corpora.length > 0) {
              let filteredNewDocTypes = {}
              Object.keys(newDocTypes).forEach(docId => {
                params.corpora.forEach(corpus => {
                  if (this.state.docediting.documentsPerCorpus[corpus].includes(docId)) {
                    filteredNewDocTypes[docId] = newDocTypes[docId]
                  }
                })
              })
              newDocTypes = filteredNewDocTypes
            }
            if (Object.keys(newDocTypes).length > 0) {
              results.push({
                "form": type,
                "type_id": index,
                "document_id_token_ids": newDocTypes
              })
            }
          }
        })
      }
      commit('setTypes', results);
    }
    else {
      axios
        .post(`${API_URL}/token/types`, {
          queryRegex: params.queryRegex,
          searchBy: params.searchBy,
          corpuses: params.corpora,
          languages: params.languages,
          lemmatized: params.lemmatized
        })
        .then(r => r.data)
        .then(types => {
          commit('setTypes', types);
        })
    }
  },
  loadContext({ commit }, selectedForms) {
    if (!config.editable) {
      // console.log("AASD", selectedForms)
      let results = {documents: {}, results: [], lemmata: {}, hccs: null}
      let documentIds = []
      let tokensIds = []
      let typeIds = []
      for (let token of selectedForms){
        for (let documentId in token.document_id_token_ids){
          documentIds.push(documentId)
          // token.document_id_token_ids[documentId].forEach(tokensIds.add, tokensIds)
          tokensIds.push(token.document_id_token_ids[documentId])
          typeIds.push(token.type_id)
        }
      }
      documentIds.forEach((documentId, index) => {
        let document = this.state.docediting.allDocuments[documentId]
        // 0 corpus name (= edition sigle)
        // 1 document name (= edition sigle)
        // 2 date
        // 3 redactors (= scriptoria)
        // 4 scriptae
        // 5 token IDs
        results.documents[documentId] = {
          date: document[2],
          sigle_legam: document[1],
          scriptorium: Array.isArray(document[3]) ? document[3].join(', ') : '',
          scripta: Array.isArray(document[4]) ? document[4].join(', ') : '',
        }

        let typeX = this.state.docediting.allTypes[typeIds[index]]
        console.log("Type", typeX, index, typeIds[index], this.state.docediting.typesToLemma[typeIds[index]], this.state.docediting.typesToLemma[233])

        tokensIds[index].forEach(tokenIndex => {
          let context = []
          document[5].forEach((typeIndex, tIndex) => {
            if (tIndex >= tokenIndex - 50 && tIndex <= tokenIndex + 50) {
              context.push(this.state.docediting.allTypes[typeIndex])
            }
          })
          let contentIndex = Math.min(49, tokenIndex-1)
          results.results.push({
            document_id: documentId,
            form: context[contentIndex],
            left: context.slice(0, contentIndex).join(' '),
            right: context.slice(contentIndex+1, contentIndex+50).join(' '),
            lemma_id: [1],
            hcc_id: null,
            lang: null,
            token_id: tokenIndex,
          })
        })
        results.lemmata[1] = {
          language_code: 'lc',
          form: 'lform'
        }
      })
      commit('setContext', results);
    }
    else {
      let documentIds = []
      let tokensIds = []
      for (let token of selectedForms){
        Object.keys(token.document_id_token_ids).forEach(documentId => {
          documentIds.push(documentId)
          tokensIds = [
            ...tokensIds,
            ...token.document_id_token_ids[documentId]
          ]
        })
      }
      axios
        .post(`${API_URL}/token/load-context`, {
          tokens: tokensIds,
          documents: documentIds
        })
        .then(r => r.data)
        .then(context => {
          commit('setContext', context);
        })
    }
  },
  assignTokensToLemma(_, data) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/${data.lemmaId}/assign_tokens`, { tokenIds: data.tokenIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  removeAssignedLemmas(_, data) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/lemma/unassign_tokens`, { tokenIds: data.tokenIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
}

export default {
    state,
    getters,
    actions,
    mutations
}
