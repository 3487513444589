import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  baseindiv: null,
  baseindivs: []
}

const mutations = {
  setBaseindiv(state, baseindiv){
    state.baseindiv = baseindiv
  },
  setBaseindivs(state, baseindivs){
    state.baseindivs = baseindivs
  }
}

const getters = {
}

const actions = {
  loadBaseindiv({ commit }, baseindivId){
    axios
      .get(`${API_URL}/baseindiv/${baseindivId}`)
      .then(r => r.data)
      .then(baseindiv => {
        commit('setBaseindiv', baseindiv)
      })
  },
  searchBaseindivs({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/baseindivs/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(baseindivs => {
        commit('setBaseindivs', baseindivs)
      })
  },
  saveBaseindiv(_, baseindiv){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/baseindiv/${baseindiv.id}`, baseindiv)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createBaseindiv(_, baseindiv){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/baseindiv`, baseindiv)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Base indiv created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteBaseindiv(_, baseindiv){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/baseindiv/${baseindiv.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Base indiv deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
