import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide
let MAIN_URL = API_URL
let SUFFIX = ''
if (!config.editable) {
  MAIN_URL = API_URL_CLIENTSIDE
  SUFFIX = '.json'
}

const state = {
  study: null,
  studies: []
}

const mutations = {
  async setStudy(state, study){
    if (!config.editable) {
      study = await exportConvert.convert("study.details", study)
    }
    state.study = study
  },
  setStudies(state, studies){
    state.studies = studies
  }
}

const getters = {
}

const actions = {
  loadStudy({ commit }, studyId){
    let url = `${MAIN_URL}/study/${studyId}${SUFFIX}`
    if (!config.editable) {
      url = `${MAIN_URL}/study/${studyId.slice(0, 2)}/${studyId}${SUFFIX}`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(study => {
        commit('setStudy', study)
      })
  },
  loadStudies({ commit }){
    const last = config.editable ? '/all' : '.json'
    axios
      .get(`${MAIN_URL}/studies${last}`)
      .then(r => r.data)
      .then(studies => {
        commit('setStudies', studies)
      })
  },
  searchStudies({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/studies/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(studies => {
        commit('setStudies', studies)
      })
  },
  mergeStudies(_, { mainStudyId, studyIds }){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/studies/merge`, { mainStudyId, studyIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  saveStudy(_, study){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/study/${study.id}`, study)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          // reject(error.response.data)
        })
    })
  },
  createStudy(_, study){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/study`, study)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Study created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteStudy(_, study){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/study/${study.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Study deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
