import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  baseetym: null,
  baseetyms: []
}

const mutations = {
  setBaseetym(state, baseetym){
    state.baseetym = baseetym
  },
  setBaseetyms(state, baseetyms){
    state.baseetyms = baseetyms
  }
}

const getters = {
}

const actions = {
  loadBaseetym({ commit }, baseetymId){
    axios
      .get(`${API_URL}/baseetym/${baseetymId}`)
      .then(r => r.data)
      .then(baseetym => {
        commit('setBaseetym', baseetym)
      })
  },
  searchBaseetyms({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/baseetyms/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(baseetyms => {
        commit('setBaseetyms', baseetyms)
      })
  },
  saveBaseetym(_, baseetym){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/baseetym/${baseetym.id}`, baseetym)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createBaseetym(_, baseetym){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/baseetym`, baseetym)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Base etym created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteBaseetym(_, baseetym){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/baseetym/${baseetym.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Base etym deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
