<template>
  <div id="app" :class="appClasses">
    <b-navbar toggleable="lg" type="dark" variant="secondary" :data-transparent="navbarTransparent" sticky>
      <b-navbar-brand to="/">{{ config.editable ? 'LEGaM' : 'GallRom' }}</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav v-if="isLoggedIn">
        <b-navbar-nav>
          <!-- <b-nav-item to="/">{{ $t('Home') }}</b-nav-item> -->

          <div @mouseover="openMenu('dropdownBibl')" @mouseleave="closeMenu('dropdownBibl')">
            <b-nav-item-dropdown
              id="bibliography_dropdown"
              text="GallRomBibl"
              toggle-class="nav-link-custom"
              data-link="/gallrombibl"
              dropright
              ref="dropdownBibl"
            >
              <b-dropdown-item to="/searchsigleentities">{{ $t('Search_Sigle_Entities') }}</b-dropdown-item>
              <b-dropdown-item to="/editions">{{ $t('Editions') }}</b-dropdown-item>
              <b-dropdown-item to="/texts">{{ $t('Texts') }}</b-dropdown-item>
              <b-dropdown-item to="/metatexts">{{ $t('Metatexts') }}</b-dropdown-item>
              <b-dropdown-item to="/studies">{{ $t('Studies') }}</b-dropdown-item>
              <b-dropdown-item to="/manuscripts">{{ $t('Manuscripts') }}</b-dropdown-item>
              <b-dropdown-item to="/annotators">{{ $t('Annotators') }}</b-dropdown-item>
              <b-dropdown-item to="/historicalAuthors">{{ $t('Historical_Authors') }}</b-dropdown-item>
              <b-dropdown-item to="/authors">{{ $t('Scholars') }}</b-dropdown-item>
              <b-dropdown-item to="/reviews">{{ $t('Reviews') }}</b-dropdown-item>
              <b-dropdown-item to="/personhists">{{ $t('Personhists') }}</b-dropdown-item>
              <b-dropdown-item to="/scriptoria">{{ $t('Scriptoria') }}</b-dropdown-item>
              <b-dropdown-item to="/locations">{{ $t('Locations') }}</b-dropdown-item>
              <b-dropdown-item to="/scriptae">{{ $t('Scriptae') }}</b-dropdown-item>
              <b-dropdown-item to="/writingtypes">{{ $t('Writing_Types') }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </div>

          <div @mouseover="openMenu('dropdownDocLing')" @mouseleave="closeMenu('dropdownDocLing')">
            <b-nav-item-dropdown
              id="document_dropdown"
              text="DocLing"
              toggle-class="nav-link-custom"
              dropright
              ref="dropdownDocLing"
              data-link="/docling"
            >
              <b-dropdown-item to="/documents">{{ $t('DocLing_Texts') }}</b-dropdown-item>
              <b-dropdown-item to="/documentManagementSystem">{{ $t('DocLing_Search') }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </div>

          <div @mouseover="openMenu('dropdownDictionary')" @mouseleave="closeMenu('dropdownDictionary')">
            <b-nav-item-dropdown
              id="dictionary_dropdown"
              :text="$t('Dictionary')"
              toggle-class="nav-link-custom"
              dropright
              ref="dropdownDictionary"
              data-link="/legame"
            >
              <!-- <b-dropdown-item to="/articles">Articles</b-dropdown-item> -->
              <b-dropdown-item to="/lemmaSearch">{{ $t('Search_Lemmata') }}</b-dropdown-item>
              <b-dropdown-item to="/lemmas">{{ $t('Lemmas') }}</b-dropdown-item>
              <b-dropdown-item to="/senses">{{ $t('Senses') }}</b-dropdown-item>
              <b-dropdown-item to="/categories">{{ $t('Categories') }}</b-dropdown-item>
              <b-dropdown-item to="/protospellings">{{ $t('Protospellings') }}</b-dropdown-item>
              <b-dropdown-item to="/occurrences/external">{{ $t('Occurrences') }}</b-dropdown-item>
              <b-dropdown-item to="/references">{{ $t('References') }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </div>

          <b-nav-item-dropdown v-if="config.editable" id="annotation_dropdown" text="Tagging"
            toggle-class="nav-link-custom" dropright>
            <b-dropdown-item to="/heads">Head</b-dropdown-item>
            <li class="dropdown-submenu">
              <a class="dropdown-item dropdown-toggle" href="#">Parameter</a>
              <ul class="dropdown-menu">
                <b-dropdown-item to="/phonolenvs">PhonolEnvs</b-dropdown-item>
                <b-dropdown-item to="/baseetyms">BaseEtyms</b-dropdown-item>
                <b-dropdown-item to="/baseetymvars">BaseEtymVars</b-dropdown-item>
                <b-dropdown-item to="/baseindivs">BaseIndivs</b-dropdown-item>
                <b-dropdown-item to="/basespecials">BaseSpecials</b-dropdown-item>
                <b-dropdown-item to="/evolutions">Evolutions</b-dropdown-item>
                <b-dropdown-item to="/issuetypes">IssueTypes</b-dropdown-item>
                <b-dropdown-item to="/issuevars">IssueVars</b-dropdown-item>
                <b-dropdown-item to="/issuephons">IssuePhons</b-dropdown-item>
              </ul>
            </li>
            <b-dropdown-item to="/hccStats">HCC Statistics</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item to="/map" v-if="config.editable">Map</b-nav-item>
        </b-navbar-nav>


        <b-navbar-nav class="ml-auto text-white">
          <b-nav-item to="/publications">{{ $t('Publications') }}</b-nav-item>
          <b-nav-item @click="setLanguage('en')" class="small-caps ml-5 lang-choose"
            :class="$i18n.locale == 'en' ? 'text-bold' : ''">EN</b-nav-item>
          <b-nav-item @click="setLanguage('fr')" class="small-caps lang-choose"
            :class="$i18n.locale == 'fr' ? 'text-bold' : ''">FR</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto text-white" v-if="config.editable">
          <!-- <b-button-group size="sm">
                  <b-button v-for="label in labels" :key="label.id" :variant="selected == label.id ? 'danger' : 'info'">{{ label.label }}</b-button>
                </b-button-group> -->

          <b-nav-item>{{ username }}</b-nav-item>
          <b-nav-item>
            <b-icon v-b-modal.modal-settings icon="gear-fill" id="openFilterIcon" aria-hidden="true" class="pointer"
              title="Filters"></b-icon>

            <b-modal id="modal-settings" title="Settings" ok-only>
              <UserSettings />
            </b-modal>
          </b-nav-item>

          <!-- <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    v-model="selected"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-inline"
                  ></b-form-radio-group>
                </b-form-group> -->
          <!-- <b-nav-item>Logged in as <b>{{ username }}</b></b-nav-item> -->
          <!-- <b-navbar-nav class="ml-auto">
                <b-nav-form>
                  <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                  <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                </b-nav-form>

                <b-nav-item-dropdown text="Lang" right>
                  <b-dropdown-item href="#">EN</b-dropdown-item>
                  <b-dropdown-item href="#">ES</b-dropdown-item>
                  <b-dropdown-item href="#">RU</b-dropdown-item>
                  <b-dropdown-item href="#">FA</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown right>
                  <!- - Using 'button-content' slot - ->
                  <template #button-content>
                    <em>User</em>
                  </template>
                  <b-dropdown-item href="#">Profile</b-dropdown-item>
                  <b-dropdown-item href="#">Sign Out</b-dropdown-item>
                </b-nav-item-dropdown> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view class="page-data" />
    <Notifications />
    <LoadingStatus />

    <footer>
      <b-container fluid="xxl">
        <b-row>
          <b-col cols="12" class="footer-logos mb-3">
            <img src="/images/logos-white/snf.png">
            <img src="/images/logos-white/uzh.svg">
            <img src="/images/logos-white/akademie.png">
            <img src="/images/logos-white/enc.png">
            <img src="/images/logos-white/ephe.png">
          </b-col>
          <b-col cols="12" class="footer-logos mt-3">
            <img src="/images/logos-white/unil-uni.png">
            <img src="/images/logos-white/su.png">
            <img src="/images/logos-white/unibz.png">
            <img src="/images/logos-white/tirol.png">
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-5">
            © 2024 GallRom
          </b-col>
        </b-row>
      </b-container>
    </footer>

    <div v-if="!config.editable && !PIUserEmail" class="modal-get-user-email">
      <div class="modal-user-inner">
        Please enter your email address to access the GallRom public interface, which is currently in a provisional state
        but can be used with the necessary caution:
        <form class="mt-4">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" v-model="email" name="email" class="form-control" />
          </div>
          <div class="form-group">
            <button class="btn btn-primary" :disabled="!validateEmail" @click="setEmail">OK</button>
          </div>
        </form>
        Any corrections or additions may be adressed to <a href="mailto:glessgen@rom.uzh.ch">glessgen@rom.uzh.ch</a>.
      </div>
    </div>

    <div :class="'environment-mark ' + environment" v-if="config.editable">
      <span class="text-uppercase">{{ environment }} </span>
      <small>v{{ version }}</small>
      <div class="last-commit">#{{ lastCommit }}</div>
    </div>
  </div>
</template>

<script>
import Notifications from "@/components/Notifications.vue";
import LoadingStatus from "@/components/LoadingStatus.vue";
import UserSettings from "@/components/UserSettings.vue";
import store from "@/store";
import config from "@/config.js";
import router from '@/router'
import Utils from '@/utils.js'

let userSettingsPI = JSON.parse(localStorage.getItem('userSettingsPI'))
const hasEmail = userSettingsPI && userSettingsPI.email && userSettingsPI.email.length > 0

export default {
  name: "App",
  components: {
    Notifications,
    LoadingStatus,
    UserSettings,
  },
  data() {
    return {
      config: config,
      navbarTransparent: 1,
      PIUserEmail: hasEmail,
      email: '',
    };
  },
  computed: {
    isLoggedIn() {
      return true;
      // return store.getters.isLoggedIn
    },
    version() {
      return store.getters.appSettings.version;
    },
    lastCommit() {
      return store.getters.appSettings.lastCommit;
    },
    environment() {
      return store.getters.appSettings.environment;
    },
    username() {
      return store.getters.appSettings.user
        ? store.getters.appSettings.user.display
        : "";
    },
    appClasses() {
      let classes = []
      if (!config.editable) {
        classes.push('public-ui')
      }
      if (this.$route.path === '/') {
        classes.push('home-page')
      }
      return classes
    },
    validateEmail() {
      return Utils.validateEmail(this.email)
    },
  },
  created() {
    window.addEventListener("scroll", this.updateNavTrasparent);
  },
  mounted() {
    let dropdownMenuItems = document.getElementsByClassName("nav-link-custom")
    for (let item of dropdownMenuItems) {
      item.addEventListener("click", (event) => {
        if (event.target.closest(".b-nav-dropdown").dataset.link) {
          router.push(event.target.closest(".b-nav-dropdown").dataset.link)
        }
      });
    }
  },
  methods: {
    setEmail(ev) {
      ev.preventDefault()
      let userSettingsPI = JSON.parse(localStorage.getItem('userSettingsPI'))
      userSettingsPI.email = this.email
      this.PIUserEmail = true
      localStorage.setItem('userSettingsPI', JSON.stringify(userSettingsPI))
      return false
    },
    setLanguage(language) {
      localStorage.setItem('language', language)
      this.$i18n.locale = language
    },
    updateNavTrasparent() {
      this.navbarTransparent =
        this.$route.path === "/" && window.scrollY < 50 ? 1 : 0;
    },
    openMenu(el) {
      this.$refs[el].visible = true;
    },
    closeMenu(el) {
      this.$refs[el].visible = false;
    },
  },
  watch: {
    $route() {
      this.updateNavTrasparent();
    },
  },
};
</script>

<style scoped>
footer {
  width: 100%;
  /* height: 120px; */
  background-color: var(--main-color);
  color: #fff;
  padding: 30px;
  padding-top: 50px;
}

#app:not(.home-page) footer {
  margin-top: 50px;
}

.modal-get-user-email {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #000000b0;
  z-index: 10000;
}

.modal-user-inner {
  width: 500px;
  margin: auto;
  height: 350px;
  margin-top: 10%;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
}

.footer-logos img {
  height: 50px;
  margin-right: 35px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style>
:root {
  /* --main-color: #6c757d;
    --danger-color: #dc3545;
    */
  --main-color: #8c1615;
  /* --main-color: #06086d; */
  --basic-color: #696969;
  /* --second-color: #991e26; */
  --second-color: #48bcd1;
  --danger-color: #e0703b;
}

/* Multiselect styles */
span.multiselect__option--group {
  font-weight: bold;
}

span.multiselect__option:not(.multiselect__option--group) {
  padding-left: 40px;
}

span.multiselect__option--selected {
  background-color: #1e99893b;
}

li.multiselect__element {
  position: relative;
}

span.multiselect__option--selected:before {
  position: absolute;
  content: "✓";
  left: 15px;
}

/* End multiselect styles */

.white-space-pre-line {
  white-space: pre-line;
}

body {
  background-image: url(/images/webbg.jpg);
  font-size: 18px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(0deg);
  position: absolute;
  right: 6px;
  top: 0.8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.page-data {
  min-height: calc(100vh - 150px - 60px);
}

.dropright .dropdown-menu {
  left: 94% !important;
}

.dropdown-submenu:hover .dropdown-menu {
  display: block;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  background-color: #a44443 !important;
  border-color: #8b3534 !important;
}

txtsc {
  font-variant: small-caps;
}

*[visible="false"] {
  display: none;
  visibility: hidden;
}

.last-commit {
  font-size: 9px;
  font-weight: 400;
}

.environment-mark {
  text-align: center;
  position: fixed;
  top: 0;
  right: 15rem;
  color: #fff;
  z-index: 10000;
  font-size: 10px;
  font-weight: bold;
  background-color: #b50b0b;
  padding: 6px 20px 5px;
  border-radius: 0 0 5px 5px;
  box-shadow: 1px 1px 5px -3px #272727;
}

.environment-mark.staging {
  background-color: #0bb533;
}

.environment-mark.develop,
.environment-mark.development {
  background-color: var(--second-color);
}

.environment-mark.test {
  background-color: #48bcd1;
}

.multiselect__tag {
  background-color: var(--main-color);
}

.multiselect__tag-icon:after {
  content: "\D7";
  color: #fff;
  font-size: 14px;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #4c5258;
}

.multiselect--active {
  z-index: 10000;
}

hoch {
  vertical-align: super;
  font-size: 75%;
}

.nav-item {
  font-size: 20px;
  /* font-weight: bold; */
}

@font-face {
  font-family: LMRoman;
  src: url("/font/lmroman10-regular.otf") format("opentype");
}

@font-face {
  font-family: LMRoman;
  font-weight: bold;
  src: url("/font/lmroman10-bold.otf") format("opentype");
}

@font-face {
  font-family: LMRoman;
  font-style: italic;
  src: url("/font/lmroman10-italic.otf") format("opentype");
}

@font-face {
  font-family: LMRoman;
  font-weight: bold;
  font-style: italic;
  src: url("/font/lmroman10-bolditalic.otf") format("opentype");
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: LMRoman, Georgia, "Times New Roman";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  color: #281f0b;
}

html {
  margin-bottom: 0px;
}

.text-bold {
  font-weight: bold;
}

.small-caps {
  font-variant: small-caps;
}

.lang-choose {
  font-size: 80%;
  padding-top: 4px;
}

#app.public-ui>div a:not([class^="btn"]) {
  color: #158b8c;
}

#app.public-ui>div a:hover:not([class^="btn"]) {
  color: #075455;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

locution,
collocation {
  font-style: italic;
}

remark {
  display: block;
  margin-top: 10px;
}

remark:before {
  content: "REM. Nº: ";
  font-size: 10px;
  line-height: 7px;
  font-weight: bold;
}

.tooltip-box {
  position: relative;
  border-radius: 5px;
}

.tooltip-box .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #5a6268e3;
  opacity: 0.9;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0px 4px 4px;
  position: absolute;
  z-index: 1;
  /* bottom: -1px; */
  /* left: 150px; */
  margin-left: 10px;
}

.legam-details-box {
  border: 1px solid #e8e8e8;
  padding: 13px;
  border-radius: 5px;
}

.mt-4-8,
.my-4-8 {
  margin-top: 2.76rem !important;
}

/* .tooltip-box-w60 .tooltiptext {
    width: 60px;
  }

  .tooltip-box-w110 .tooltiptext {
    width: 110px;
  }

  .tooltip-box-w150 .tooltiptext {
    width: 150px;
  }

  .tooltip-box-w180 .tooltiptext {
    width: 180px;
  }

  .tooltip-box-w220 .tooltiptext {
    width: 220px;
  }

  .tooltip-box-w270 .tooltiptext {
    width: 270px;
  } */

/* .tooltip-box-w110 .tooltiptext {
    width: 110px;
  } */

/* .tooltip-box .tooltiptext::after {
    content: "";
    position: absolute;
    top: 17%;
    right: 100%;
    margin-left: 200px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #5a6268e3 transparent transparent ;
  } */

.tooltip-box:not(.edit-hover):hover .tooltiptext {
  visibility: visible;
}

.tooltip-box .contextmenu {
  visibility: hidden;
  width: auto;
  background-color: #5a6268ed;
  opacity: 0.9;
  color: #fff;
  border-radius: 4px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  margin-left: 10px;
}

.tooltip-box .contextmenu>.separator {
  height: 1px;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: #ffffff35;
}

.tooltip-box .contextmenu>button,
.tooltip-box .contextmenu>a {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

.lemmaDetails .accordion>.card {
  overflow: visible;
}

.tooltip-box:not(.edit-hover):hover .contextmenu {
  visibility: visible;
  /* position: fixed;
  top: 10px;
  left: 10px; */
}

.tooltip-box:hover {
  background-color: #e8e8e875;
}

.btn-legam-tooltip {
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1px 6px;
  text-decoration: none;
}

.btn-legam-tooltip:hover {
  color: rgb(189, 189, 189);
  text-decoration: none;
}

.legam-tree-list-tabs .nav-tabs .nav-link {
  padding: 0.4rem 1rem 0.3rem;
  text-transform: uppercase;
  font-size: 10px;
}

.legam-tree-list-tabs.tabs {
  margin-top: -28px;
}

.legam-tree-list-tabs.tabs.no-mt {
  margin-top: 0;
}

.btn-legam-xs {
  color: #fff;
  padding: 6px 10px 4px;
  border-radius: 5px;
  margin-right: 3px;
  /* background-color: #6c757d;
    border-color: #6c757d; */
  background-color: var(--basic-color);
  border-color: var(--basic-color);
  cursor: pointer;
  text-transform: uppercase;
  font-size: 10px;
  transition: all 0.3s;
  font-weight: bold;
  border: none;
}

.btn-legam-xxs {
  padding: 4px 7px 3px;
}

.btn-legam-dt-filter {
  padding: 8px 16px 7px;
  border-radius: 4px;
}

.btn-legam-blue {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-legam-red {
  background-color: #d00000;
  border-color: #d00000;
}

.red {
  color: #e94040;
  font-size: 10px;
}

.btn-legam-xs:hover {
  opacity: 0.9;
}

.btn-legam-xs[disabled],
.btn-legam-xs[disabled]:hover {
  opacity: 0.7;
  background-color: #6c757d;
  border-color: #6c757d;
  color: #a9aeb3;
}

.btn-legam-xs.btn-danger {
  color: #fff;
  background-color: var(--danger-color);
  border-color: var(--danger-color);
}

.btn-legam-xs.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-legam-xs.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-legam-xs.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.text-blue {
  color: rgb(11, 117, 255);
}

.text-orange {
  color: rgb(255, 88, 11);
}

.text-violet {
  color: #8a2be2;
}

.text-red {
  color: #dc143c;
}

.text-green {
  color: #1e9989;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.select-description {
  font-size: 11px;
  color: #636363;
  display: block;
}

.select2-results__option--highlighted .select-description {
  color: #e0e0e0;
}

.with-line {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 4px;
  padding-top: 4px;
}

.legam-h6,
h6 {
  font-size: 10px !important;
  text-transform: uppercase;
  /* color: #bbbbbb; */
  color: #5a543e;
}

.legam-h5,
h5 {
  font-size: 14px !important;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-60 {
  opacity: 0.6;
}

.red-caution {
  color: red;
  font-size: 11px;
  text-transform: uppercase;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.35em !important;
}

.pointer {
  cursor: pointer;
}

.collapsed>.when-open,
.not-collapsed>.when-closed {
  display: none;
}

/* SELECT2 */
.select2-container .select2-selection--single {
  height: 38px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 37px !important;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #ced4d9 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px !important;
}

.select2-dropdown {
  border: 1px solid #ced4d9 !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #ced4d9 !important;
  outline: none;
  border-radius: 4px;
}

.select2-container--default .select2-search--dropdown .select2-search__field:hover,
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border-color: #92979b !important;
}

.select2-results__option {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb !important;
  color: white !important;
}

.form-control::placeholder {
  color: rgb(223, 223, 223) !important;
}

.select2-close-mask {
  z-index: 2099;
}

.select2-dropdown {
  z-index: 3051;
}

cited-word {
  font-style: italic;
}

nav.navbar-dark,
nav.bg-secondary {
  background-color: var(--main-color) !important;
}

nav {
  transition: all 0.5s;
}

nav[data-transparent="1"] {
  /* background-color: transparent !important; */
  background-color: #8c171578 !important;
}

.btn-secondary {
  /* border-color: var(--basic-color) !important;
    background-color: var(--basic-color) !important; */
  border-color: var(--main-color) !important;
  background-color: var(--main-color) !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7) !important;
  transition: all 0.3s;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff !important;
}

.btn-primary {
  border-color: var(--second-color) !important;
  background-color: var(--second-color) !important;
}

.page-item.active .page-link {
  border-color: var(--second-color) !important;
  background-color: var(--second-color) !important;
  color: #fff !important;
}

.page-link {
  color: var(--second-color) !important;
}

.badge-secondary {
  background-color: var(--main-color) !important;
}

basetym::before {
  content: "Base étymologique: ";
}

emph {
  font-style: italic;
}

/* XXL grid */
@media (min-width: 1600px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1520px;
  }
}

.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

*>>>.pagination {
  justify-content: flex-end !important;
}

*>>>.flex-fill {
  flex: none !important;
}

.card-header {
  background-color: none;
  border-bottom: none !important;
}

.card {
  border: none !important;
}
</style>
