import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  baseetymvar: null,
  baseetymvars: []
}

const mutations = {
  setBaseetymvar(state, baseetymvar){
    state.baseetymvar = baseetymvar
  },
  setBaseetymvars(state, baseetymvars){
    state.baseetymvars = baseetymvars
  }
}

const getters = {
}

const actions = {
  loadBaseetymvar({ commit }, baseetymvarId){
    axios
      .get(`${API_URL}/baseetymvar/${baseetymvarId}`)
      .then(r => r.data)
      .then(baseetymvar => {
        commit('setBaseetymvar', baseetymvar)
      })
  },
  searchBaseetymvars({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/baseetymvars/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(baseetymvars => {
        commit('setBaseetymvars', baseetymvars)
      })
  },
  saveBaseetymvar(_, baseetymvar){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/baseetymvar/${baseetymvar.id}`, baseetymvar)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createBaseetymvar(_, baseetymvar){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/baseetymvar`, baseetymvar)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Base etym var created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteBaseetymvar(_, baseetymvar){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/baseetymvar/${baseetymvar.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Base etym var deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
