import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  personhist: null,
  personhists: []
}

const mutations = {
  async setPersonhist(state, personhist){
    if (!config.editable) {
      personhist = await exportConvert.convert("personhist.details", personhist)
    }
    state.personhist = personhist
  },
  setPersonhists(state, personhists){
    state.personhists = personhists
  }
}

const getters = {
}

const actions = {
  loadPersonhist({ commit }, personhistId){
    let url = `${API_URL}/personhist/${personhistId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/personhist/${personhistId.slice(0, 2)}/${personhistId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(personhist => {
        commit('setPersonhist', personhist)
      })
  },
  searchPersonhists({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/personhists/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(personhists => {
        commit('setPersonhists', personhists)
      })
  },
  savePersonhist(_, personhist){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/personhist/${personhist.id}`, personhist)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createPersonhist(_, personhist){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/personhist`, personhist)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Person hist created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deletePersonhist(_, personhist){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/personhist/${personhist.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Person hist deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
