import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  review: null,
  reviews: []
}

const mutations = {
  async setReview(state, review){
    if (!config.editable) {
      review = await exportConvert.convert("review.details", review)
    }
    state.review = review
  },
  setReviews(state, reviews){
    state.reviews = reviews
  }
}

const getters = {
}

const actions = {
  loadReview({ commit }, reviewId){
    let url = `${API_URL}/review/${reviewId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/review/${reviewId.slice(0, 2)}/${reviewId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(review => {
        commit('setReview', review)
      })
  },
  searchReviews({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/reviews/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(reviews => {
        commit('setReviews', reviews)
      })
  },
  saveReview(_, review){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/review/${review.id}`, review)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createReview(_, review){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/review`, review)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Review created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteReview(_, review){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/review/${review.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Review deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
