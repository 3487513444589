<template>
  <div id="user-settings-box">
    <b-form-group
      id="dictionary-group"
      label="Preferred Dictionaries"
      label-for="dictionaries"
      description="The filter will be applied to all entities in the dictionary"
      class="mb-4"
    >
      <multiselect
        v-model="dictionariesSelected"
        :options="dictionaryOptions"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Pick some"
        label="text"
        track-by="text"
        :preselect-first="false"
      >
      </multiselect>
    </b-form-group>

    <b-form-group
      id="bib-labels-group"
      label="Preferred Bibliography Labels"
      label-for="bib-labels"
      description="The filter will be applied to all entities in the bibliography"
    >
      <multiselect
        v-model="biblabelsSelected"
        :options="biblabelOptions"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Pick some"
        label="text"
        track-by="text"
        :preselect-first="false"
      >
      </multiselect>
    </b-form-group>

    <b-form-group
      id="bib-labels-group"
      label="Suppressed Bibliography Labels"
      label-for="bib-labels"
      description="All entities in the bibliography with selected labels will be hidden"
    >
      <multiselect
        v-model="biblabelsSuppressedSelected"
        :options="biblabelOptions"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Pick some"
        label="text"
        track-by="text"
        :preselect-first="false"
      >
      </multiselect>
    </b-form-group>

    <b-form-group label="List view order">
      <b-form-radio v-model="listViewOrder" name="list-view-order" value="lexical">lexical</b-form-radio>
      <b-form-radio v-model="listViewOrder" name="list-view-order" value="flattened-hierarchy">hierarchical/historical</b-form-radio>
    </b-form-group>
    <!-- <button class="btn-legam-xs btn-legam-dt-filter" @click="save">Save</button>
    <button class="btn-legam-xs btn-legam-dt-filter" @click="close">Close</button> -->
  </div>
</template>

<style scoped>
  .filtered {
    background-color: #6c757d;
    border: 1px solid #ced4da;
  }

  .not-filtered {
    color: #6c757d;
    background-color: #fff;
    border: 1px solid #ced4da;
  }

  .filter-box {
    position: absolute;
    background-color: #e8e8e8;
    padding: 10px;
    border-radius: 3px;
    z-index: 10;
    top: 10px;
    left: 20px;
    width: 350px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'UserSettings',
    props: [],
    data(){
      return {
        show: false,
        biblabelsSelected: [],
        dictionariesSelected: [],
        biblabelsSuppressedSelected: [],
        listViewOrder: null
      }
    },
    components: {
      Multiselect
    },
    computed: {
      ...mapState({
        biblabels: state => state.common.biblabels,
        userSettings: state => state.user.settings,
        dictionaries: state => state.common.dictionaries
      }),
      biblabelOptions(){
        return this.biblabels.map(biblabel => {
          return {
            value: biblabel.id,
            text: biblabel.label
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      },
      dictionaryOptions(){
        return this.dictionaries.map(dictionary => {
          return {
            value: dictionary.id,
            text: dictionary.display
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    methods: {
      updateOptions(){
        if (this.userSettings && this.userSettings.listViewOrder){
          this.listViewOrder = this.userSettings.listViewOrder
        }
        else {
          this.listViewOrder = 'lexical'
        }

        if (this.userSettings && this.userSettings.dictionaries && this.userSettings.dictionaries.length){
          this.dictionariesSelected = this.dictionaries.filter(
            dictionary => this.userSettings.dictionaries.includes(dictionary.id)
          ).map(dictionary => {
            return {
              value: dictionary.id,
              text: dictionary.display
            }
          })
        }
        else {
          this.dictionariesSelected = this.dictionaryOptions
        }

        if (this.userSettings && this.userSettings.biblabels && this.userSettings.biblabels.length){
          this.biblabelsSelected = this.biblabels.filter(
            biblabel => this.userSettings.biblabels.includes(biblabel.id)
          ).map(biblabel => {
            return {
              value: biblabel.id,
              text: biblabel.label
            }
          })
        }
        else {
          this.biblabelsSelected = this.biblabelOptions
        }

        if (this.userSettings && this.userSettings.biblabelsSuppressed && this.userSettings.biblabelsSuppressed.length){
          this.biblabelsSuppressedSelected = this.biblabels.filter(
            biblabel => this.userSettings.biblabelsSuppressed.includes(biblabel.id)
          ).map(biblabel => {
            return {
              value: biblabel.id,
              text: biblabel.label
            }
          })
        }
      },
      storeSettings(){
        this.$store.dispatch('setUserSettings', {
          'biblabels': this.biblabelsSelected.map(biblabel => biblabel.value),
          'isBiblabelFiltered': this.biblabelsSelected.map(biblabel => biblabel.value).length != this.biblabels.length,
          'biblabelsSuppressed': this.biblabelsSuppressedSelected.map(biblabel => biblabel.value),
          'isBiblabelSuppressedFiltered': this.biblabelsSuppressedSelected.map(biblabel => biblabel.value).length != this.biblabels.length,
          'dictionaries': this.dictionariesSelected.map(dictionary => dictionary.value),
          'isDictionaryFiltered': this.dictionariesSelected.map(dictionary => dictionary.value).length != this.dictionaries.length,
          'listViewOrder': this.listViewOrder,
          'version': true
        })
      }
    },
    mounted () {
      this.$store.dispatch('loadBiblabels')
      this.$store.dispatch('loadDictionaries')
    },
    watch: {
      "biblabelsSelected"(){
        this.storeSettings()
      },
      "biblabelsSuppressedSelected"(){
        this.storeSettings()
      },
      "biblabels"(){
        if (this.biblabels.length && this.dictionaries.length){
          this.updateOptions()
        }
      },
      "listViewOrder"(){
        this.storeSettings()
      },
      "dictionariesSelected"(){
        this.storeSettings()
      },
      "dictionaries"(){
        if (this.biblabels.length && this.dictionaries.length){
          this.updateOptions()
        }
      }
    }
  }
</script>
