import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  languages: [],
  genres: [],
  heads: [],
  genresAll: [],
  scriptas: [],
  localizations: [],
  metatexts: [],
  ontologies: [],
  loadingStatus: false,
  tokentypes: [],
  dictionaries: [],
  etymologySkeletons: {},
  biblabels: [],
  articleauthors: [],
  appSettings: {}
}

const mutations = {
  setGenres(state, genres){
    state.genres = genres
  },
  setLocalizations(state, localizations){
    state.localizations = localizations
  },
  setMetatexts(state, metatexts) {
    state.metatexts = metatexts
  },
  setAllGenres(state, genres){
    state.genresAll = genres
  },
  setHeads(state, heads){
    state.heads = heads
  },
  setAnnotators(state, annotators){
    state.annotators = annotators
  },
  setBiblabels(state, biblabels){
    state.biblabels = biblabels

    // Should be moved to user.module.js
    let userSettings = {}
    if (localStorage.getItem('userSettingsPI')){
      userSettings = JSON.parse(localStorage.getItem('userSettingsPI'))
    }
    if (!userSettings || !userSettings.biblabels || userSettings.biblabels.length == 0) {
      userSettings = {
        "biblabels": state.biblabels.map(biblabel => biblabel.id),
        "dictionaries": state.dictionaries.map(dictionary => dictionary.id),
      }
      // userSettings = {"biblabels": state.biblabels.map(biblabel => biblabel.id)}
      localStorage.setItem('userSettingsPI', JSON.stringify(userSettings))
    }
  },
  setEtymologySkeletons(state, etymologySkeletons){
    state.etymologySkeletons = {}
    for (let etymologySkeleton of etymologySkeletons){
      state.etymologySkeletons[etymologySkeleton.acronym] = etymologySkeleton
    }
  },
  setArticleauthors(state, articleauthors){
    state.articleauthors = articleauthors
  },
  setScriptas(state, scriptas){
    state.scriptas = scriptas
  },
  setLanguages(state, languages){
    state.languages = languages
  },
  setDictionaries(state, dictionaries){
    state.dictionaries = dictionaries
  },
  setOntologies(state, ontologies){
    state.ontologies = ontologies
  },
  loadingStatus(state, status){
    state.loadingStatus = status
  },
  setTokentype(state, tokentypes){
    state.tokentypes = tokentypes
  },
  setAppSettings(state, settings){
    state.appSettings = settings
  }
}

const getters = {
  appSettings: function(state){
    return state.appSettings
  },
  getBibLabelsByIds: (state) => (ids) => {
    return state.biblabels.filter(biblabel => ids.includes(biblabel.id))
  },
  getDictionariesByIds: (state) => (ids) => {
    return state.dictionaries.filter(dictionary => ids.includes(dictionary.id))
  },
  getUUID: () => () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    })
  }
}

const actions = {
  loadingStatus({ commit }, status){
    commit('loadingStatus', status)
  },
  searchGenres({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/genres/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(genres => {
        commit('setGenres', genres)
      })
  },
  loadGenres({ commit }){
    let url = `${API_URL}/genres/all`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/genres.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(genres => {
        commit('setAllGenres', genres)
      })
  },
  loadHeads({ commit }){
    axios
      .get(`${API_URL}/heads/all`)
      .then(r => r.data)
      .then(heads => {
        commit('setHeads', heads)
      })
  },
  loadAppSettings({ commit }){
    axios
      .get(`${API_URL}/settings`)
      .then(r => r.data)
      .then(settings => {
        commit('setAppSettings', settings)
      })
  },
  loadEtymologySkeletons({ commit }){
    axios
      .get(`${API_URL}/etymologyskeleton/all`)
      .then(r => r.data)
      .then(etymologySkeletons => {
        commit('setEtymologySkeletons', etymologySkeletons)
      })
  },
  loadAnnotators({ commit }){
    axios
      .get(`${API_URL}/annotators/all`)
      .then(r => r.data)
      .then(annotators => {
        commit('setAnnotators', annotators)
      })
  },
  loadBiblabels({ commit }){
    let url = `${API_URL}/biblabels/all`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/biblabels.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(biblabels => {
        commit('setBiblabels', biblabels)
      })
  },
  loadArticleauthors({ commit }){
    axios
      .get(`${API_URL}/articleauthors/all`)
      .then(r => r.data)
      .then(articleauthors => {
        commit('setArticleauthors', articleauthors)
      })
  },
  loadDictionaries({ commit }){
    let url = `${API_URL}/dictionaries/all`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/dictionaries.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(dictionaries => {
        commit('setDictionaries', dictionaries)
      })
  },
  loadLocalizations({ commit }){
    let url = `${API_URL}/localizations/all`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/localizations.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(localizations => {
        commit('setLocalizations', localizations)
      })
  },
  loadMetatexts({ commit }){
    axios
      .get(`${API_URL}/metatexts/all`)
      .then(r => r.data)
      .then(metatexts => {
        commit('setMetatexts', metatexts)
      })
  },
  searchScriptas({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/scriptas/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(scriptas => {
        commit('setScriptas', scriptas)
      })
  },
  loadLanguages({ commit }){
    axios
      .get(`${API_URL}/languages/all`)
      .then(r => r.data)
      .then(languages => {
        commit('setLanguages', languages)
      })
  },
  loadScriptas({ commit }){
    let url = `${API_URL}/scriptas/all`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/scriptas.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(scriptas => {
        commit('setScriptas', scriptas)
      })
  },
  loadTokentypes({ commit }){
    axios
      .get(`${API_URL}/tokentypes/all`)
      .then(r => r.data)
      .then(tokentypes => {
        commit('setTokentype', tokentypes)
      })
  },
  loadOntologies({ commit }){
    axios
      .get(`${API_URL}/ontologies/all`)
      .then(r => r.data)
      .then(ontologies => {
        commit('setOntologies', ontologies)
      })
  },
  loadChangeLogs(_, data){
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/changelogs/${data.objectType}/${data.objectId}`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
