import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  // titles: [],
  title: null
}

const mutations = {
  // setTitles(state, titles){
  //   state.titles = titles
  // },
  setTitle(state, title){
    state.title = title
  },
}

const getters = {
  // getArticle: (state) => (id) => state.articles.filter(article => article.id == id),
}

const actions = {
  // searchTitles({ commit }, options){
  //   let query = options.query
  //   let exclude = (options.exclude || []).join(',')
  //   axios
  //     .get(`${API_URL}/titles/autocomplete?query=${query}&exclude=${exclude}`)
  //     .then(r => r.data)
  //     .then(titles => {
  //       commit('setTitles', titles)
  //     })
  // },
  loadTitle({ commit }, id){
    axios
      .get(`${API_URL}/title/${id}`)
      .then(r => r.data)
      .then(title => {
        commit('setTitle', title)
      })
  },
  saveTitle(_, title){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/title/${title.id}`, title)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createTitle(_, title){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/title`, title)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteTitle(_, title){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/title/${title.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Title deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
