import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide
let MAIN_URL = API_URL
let SUFFIX = ''
if (!config.editable) {
  MAIN_URL = API_URL_CLIENTSIDE
  SUFFIX = '.json'
}

const state = {
  edition: null,
  editions: []
}

const mutations = {
  async setEdition(state, edition){
    if (!config.editable) {
      edition = await exportConvert.convert("edition.details", edition)
    }
    state.edition = edition
  },
  setEditions(state, editions){
    state.editions = editions
  }
}

const getters = {
}

const actions = {
  loadEdition({ commit }, editionId){
    let url = `${MAIN_URL}/edition/${editionId}${SUFFIX}`
    if (!config.editable) {
      url = `${MAIN_URL}/edition/${editionId.slice(0,2)}/${editionId}${SUFFIX}`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(edition => {
        commit('setEdition', edition)
      })
  },
  searchEditions({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/editions/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(editions => {
        commit('setEditions', editions)
      })
  },
  mergeEditions(_, { mainEditionId, editionIds }){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/editions/merge`, { mainEditionId, editionIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  saveEdition(_, edition){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/edition/${edition.id}`, edition)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Edition saved`
          })
          // commit()
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          // reject(error.response.data)
        })
    })
  },
  createEdition(_, edition){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/edition`, edition)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Edition created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteEdition(_, edition){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/edition/${edition.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Edition deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
