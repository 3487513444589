const state = {
  notifications: []
}

const mutations = {
  addNewNotification(state, message){
    state.notifications.push(message)
  },
  clearNotifications(state){
    state.notifications = []
  }
}

const getters = {
  // popNotification: function(state){
  //   let notifications = state.notifications
  //   state.notifications = []
  //   return notifications
  // }
}

const actions = {
  addNotification({ commit }, message){
    commit('addNewNotification', message)
  },
  clearNotifications({ commit }){
    commit('clearNotifications')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
