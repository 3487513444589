import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  occurrence: null,
  occurrences: []
}

const mutations = {
  async setOccurrence(state, occurrence){
    if (!config.editable) {
      occurrence = await exportConvert.convert("occurrence.details", occurrence)
    }
    state.occurrence = occurrence
  },
  setOccurrences(state, occurrences){
    state.occurrences = occurrences
  }
}

const getters = {
}

const actions = {
  loadOccurrence({ commit }, id){
    let url = `${API_URL}/occurrence/${id}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/occurrence/${id.slice(0, 2)}/${id}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(occurrence => {
        commit('setOccurrence', occurrence)
      })
  },
  saveOccurrence(_, occurrence){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/occurrence/${occurrence.id}`, occurrence)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createOccurrence(_, occurrence){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/occurrence`, occurrence)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Occurrence created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteOccurrence(_, occurrence){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/occurrence/${occurrence.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Occurrence deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  changeOccurrenceSuppressed(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/occurrence/${data.occurrence.id}/suppressed`, data)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Occurrence changed`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  searchOccurrences({ commit }, options){
    let query = options.query
    let forLemmaId = options.forLemmaId || ''
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/occurrences/autocomplete?query=${query}&exclude=${exclude}&forLemmaId=${forLemmaId}`)
      .then(r => r.data)
      .then(occurrences => {
        commit('setOccurrences', occurrences)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
