import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  issuevar: null,
  issuevars: []
}

const mutations = {
  setIssuevar(state, issuevar){
    state.issuevar = issuevar
  },
  setIssuevars(state, issuevars){
    state.issuevars = issuevars
  }
}

const getters = {
}

const actions = {
  loadIssuevar({ commit }, issuevarId){
    axios
      .get(`${API_URL}/issuevar/${issuevarId}`)
      .then(r => r.data)
      .then(issuevar => {
        commit('setIssuevar', issuevar)
      })
  },
  searchIssuevars({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/issuevars/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(issuevars => {
        commit('setIssuevars', issuevars)
      })
  },
  saveIssuevar(_, issuevar){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/issuevar/${issuevar.id}`, issuevar)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createIssuevar(_, issuevar){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/issuevar`, issuevar)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Issue Var created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteIssuevar(_, issuevar){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/issuevar/${issuevar.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Issue Var deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
