import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  senses: [],
  sense: null
}

const mutations = {
  searchSenses(state, senses){
    state.senses = senses
  },
  async setSense(state, sense){
    if (!config.editable) {
      sense = await exportConvert.convert("sense.details", sense)
    }
    state.sense = sense
  }
}

const getters = {}

const actions = {
  searchSenses({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/senses/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(senses => {
        commit('searchSenses', senses)
      })
  },
  loadSense({ commit }, options){
    let url = `${API_URL}/sense/${options.senseId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/sense/${options.senseId.slice(0, 2)}/${options.senseId}.json`
    }
    if (options.lemmaId) {
      url = `${API_URL}/sense/${options.senseId}/for_lemma/${options.lemmaId}`
    }
    return new Promise((resolve) => {
      axios
        .get(url)
        .then(r => r.data)
        .then(sense => {
          commit('setSense', sense)
          resolve(sense)
        })
    })
  },
  saveSense(_, sense){
    // if (sense.categories){
    //   sense.categories = sense.categories.map(category => category.id)
    // }
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/sense/${sense.id}`, sense)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createSense(_, sense){
    // if (sense.categories){
    //   sense.categories = sense.categories.map(category => category.id)
    // }
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/sense`, sense)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteSense(_, sense){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/sense/${sense.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Sense deleted`
          })
          // commit()
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
