import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  metatexts: [],
  metatext: null
}

const mutations = {
  async setMetatext(state, metatext){
    if (!config.editable) {
      metatext = await exportConvert.convert("metatext.details", metatext)
    }
    state.metatext = metatext
  },
  // setMetatexts(state, metatexts){
  //   state.metatexts = metatexts
  // },
}

const getters = {
  // getArticle: (state) => (id) => state.articles.filter(article => article.id == id),
}

const actions = {
  // searchMetatexts({ commit }, options){
  //   let query = options.query
  //   let exclude = (options.exclude || []).join(',')
  //   axios
  //     .get(`${API_URL}/metatexts/autocomplete?query=${query}&exclude=${exclude}`)
  //     .then(r => r.data)
  //     .then(metatexts => {
  //       commit('setMetatexts', metatexts)
  //     })
  // },
  loadMetatext({ commit }, id){
    let url = `${API_URL}/metatext/${id}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/metatext/${id.slice(0, 2)}/${id}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(metatext => {
        commit('setMetatext', metatext)
      })
  },
  saveMetatext(_, metatext){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/metatext/${metatext.id}`, metatext)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  mergeMetatexts(_, { mainMetatextId, metatextIds }){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/metatexts/merge`, { mainMetatextId, metatextIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createMetatext(_, metatext){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/metatext`, metatext)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteMetatext(_, metatext){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/metatext/${metatext.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Metatext deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
