import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  reference: null,
  references: []
}

const mutations = {
  async setReference(state, reference){
    if (!config.editable) {
      reference = await exportConvert.convert("reference.details", reference)
    }
    state.reference = reference
  },
  setReferences(state, references){
    state.references = references
  }
}

const getters = {
}

const actions = {
  loadReference({ commit }, referenceId){
    let url = `${API_URL}/reference/${referenceId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/reference/${referenceId.slice(0, 2)}/${referenceId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(reference => {
        commit('setReference', reference)
      })
  },
  searchReferences({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/references/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(references => {
        commit('setReferences', references)
      })
  },
  saveReference(_, reference){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/reference/${reference.id}`, reference)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createReference(_, reference){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/reference`, reference)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteReference(_, reference){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/reference/${reference.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Reference deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
