import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  tokens: []
}

const mutations = {
  async setTokens(state, tokens){
    if (!config.editable) {
      let tokensArr = []
      for (const token of tokens) {
        tokensArr.push(
          await exportConvert.convert(`documentToken`, token)
        )
      }
      state.tokens = tokensArr
    }
    else {
      state.tokens = tokens
    }
  }
}

const getters = {
}

const actions = {
  updateToken(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/token/${data.token_id}/update-xml`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  loadTokensForDocument({ commit }, documentId){
    let url = `${API_URL}/document/${documentId}/tokens`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/document/${documentId.slice(0, 2)}/tokens-${documentId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(tokens => {
        commit('setTokens', tokens)
      })
  },
  // tokenAllowedIndices({ commit }, tokenId){
  //   axios
  //     .get(`${API_URL}/token/${tokenId}/allowed-indices`)
  //     .then(r => r.data)
  //     .then(tokens => {
  //       commit('setTokens', tokens)
  //     })
  // },
  deleteToken(_, tokenId){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/token/${tokenId}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  splitToken(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/token/${data.tokenId}/split`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
}

export default {
    state,
    getters,
    actions,
    mutations
}
