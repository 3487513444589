import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide
let MAIN_URL = API_URL
let SUFFIX = ''
if (!config.editable) {
  MAIN_URL = API_URL_CLIENTSIDE
  SUFFIX = '.json'
}

const state = {
  articles: [],
  article: null
}

const mutations = {
  setArticles(state, articles){
    state.articles = articles
  },
  async setArticle(state, article){
    if (!config.editable) {
      article = await exportConvert.convert("article", article)
      article.lemmas = article.lemmas.reduce((lemmas_obj, lemma) => {
        let retval = lemmas_obj
        retval[lemma.id] = lemma
        return retval
      }, {})
    }
    state.article = article
  }
}

const getters = {
  // getArticle: (state) => (id) => state.articles.filter(article => article.id == id),
}

const actions = {
  searchArticles({ commit }, data){
    axios
      .get(`${MAIN_URL}/articles/autocomplete?query=${data.query}&dictionaries=${data.dictionaries ? data.dictionaries.join(',') : ""}`)
      .then(r => r.data)
      .then(articles => {
        commit('setArticles', articles)
      })
  },
  loadArticle({ commit }, id){
    let url = `${MAIN_URL}/article/${id}${SUFFIX}`
    if (!config.editable) {
      url = `${MAIN_URL}/article/${id.slice(0, 2)}/${id}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(article => {
        commit('setArticle', article)
      })
  },
  loadSimpleArticle(_, id){
    let url = `${MAIN_URL}/article/${id}${SUFFIX}`
    if (!config.editable) {
      url = `${MAIN_URL}/article/${id.slice(0,2)}/${id}.json`
    }
    return new Promise((resolve) => {
      axios
        .get(url)
        .then(r => r.data)
        .then(article => {
          if (!config.editable) {
            exportConvert.convert("article", article).then((article) => {
              resolve(article)
            })
          }
          else {
            resolve(article)
          }
        })
    })
  },
  saveArticleTree(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/article/${data.articleId}/tree`, data.tree)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  moveLemmaToArticle(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/article/${data.articleId}/lemma/${data.lemmaId}/move`, {'newArticleId': data.newArticleId})
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  addLemmasToArticle(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/article/${data.articleId}/add_lemmas`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  saveArticle(_, article){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/article/${article.id}`, article)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Article saved`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createArticle(_, data){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/article`, data)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Article created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
