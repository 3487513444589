import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  basespecial: null,
  basespecials: []
}

const mutations = {
  setBasespecial(state, basespecial){
    state.basespecial = basespecial
  },
  setBasespecials(state, basespecials){
    state.basespecials = basespecials
  }
}

const getters = {
}

const actions = {
  loadBasespecial({ commit }, basespecialId){
    axios
      .get(`${API_URL}/basespecial/${basespecialId}`)
      .then(r => r.data)
      .then(basespecial => {
        commit('setBasespecial', basespecial)
      })
  },
  searchBasespecials({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/basespecials/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(basespecials => {
        commit('setBasespecials', basespecials)
      })
  },
  saveBasespecial(_, basespecial){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/basespecial/${basespecial.id}`, basespecial)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createBasespecial(_, basespecial){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/basespecial`, basespecial)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Base special created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteBasespecial(_, basespecial){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/basespecial/${basespecial.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Base special deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
