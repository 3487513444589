import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import config from '@/config.js'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

import common from "./common.module"
import sense from "./sense.module"
import lemma from "./lemma.module"
import article from "./article.module"
import author from "./author.module"
import notification from "./notification.module"
import text from "./text.module"
import metatext from "./metatext.module"
import title from "./title.module"
import manuscript from "./manuscript.module"
import study from "./study.module"
import edition from "./edition.module"
import link from "./link.module"
import user from "./user.module"
import protospelling from "./protospelling.module"
import authorhist from "./authorhist.module"
import writingtype from "./writingtype.module"
import location from "./location.module"
import category from "./category.module"
import occurrence from "./occurrence.module"
import reference from "./reference.module"
import siglesource from "./siglesource.module"
import docediting from "./docediting.module"
import personhist from "./personhist.module"
import document from "./document.module"
import corpus from "./corpus.module"
import token from "./token.module"
import review from "./review.module"
import annotator from "./annotator.module"
import scripta from "./scripta.module"
import scriptorium from "./scriptorium.module"
import issuephon from "./scripta/issuephon.module"
import issuetype from "./scripta/issuetype.module"
import issuevar from "./scripta/issuevar.module"
import baseetym from "./scripta/baseetym.module"
import baseetymvar from "./scripta/baseetymvar.module"
import baseindiv from "./scripta/baseindiv.module"
import basespecial from "./scripta/basespecial.module"
import phonolenv from "./scripta/phonolenv.module"
import head from "./scripta/head.module"
import hcc from "./scripta/hcc.module"


// Add HTTP headers to axios requests
Object.assign(axios.defaults.headers.common, config.apiHeaders)

// When axios get 302 reload page (probably we need AAI login)
// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   if (error.response.status === 302) {
//     location.reload()
//   }
//   return Promise.reject(error)
// })

const store = new Vuex.Store({
  modules: {
    common,
    sense,
    lemma,
    article,
    author,
    notification,
    text,
    metatext,
    title,
    manuscript,
    annotator,
    study,
    edition,
    link,
    user,
    protospelling,
    personhist,
    authorhist,
    writingtype,
    location,
    category,
    occurrence,
    reference,
    siglesource,
    docediting,
    document,
    corpus,
    token,
    review,
    scripta,
    scriptorium,
    issuephon,
    issuetype,
    issuevar,
    baseetym,
    baseetymvar,
    baseindiv,
    basespecial,
    phonolenv,
    head,
    hcc,
  }
})

let timer = null
let oldXHR = window.XMLHttpRequest
function newXHR() {
  let realXHR = new oldXHR();
  realXHR.addEventListener("readystatechange", function () {
    clearTimeout(timer)
    store.dispatch('loadingStatus', true)
    if (realXHR.readyState == 4) {
      if (realXHR.status == 0) {
        // Still loading
        // The same error appears when ajax cancel a previous request
        // location.reload()
      }
      else if (realXHR.status == 200) {
        // store.dispatch('loadingStatus', false)
        timer = setTimeout(() =>
          store.dispatch('loadingStatus', false),
          250
        )
      }
      else {
        store.dispatch('addNotification', {
          type: 'danger',
          text: `Something went wrong`
        })
        timer = setTimeout(() =>
          store.dispatch('loadingStatus', false),
          250
        )
      }
    }
  }, false)
  return realXHR
}
window.XMLHttpRequest = newXHR

if (config.editable) {
  // Load user roles
  store.dispatch('getRoles')

  // Load data
  store.dispatch('loadAppSettings')
  store.dispatch('loadBiblabels')
  store.dispatch('loadAnnotators')
  store.dispatch('loadArticleauthors')
  store.dispatch('loadDictionaries')
  store.dispatch('loadEtymologySkeletons')
  store.dispatch('loadLanguages')
} else {
  store.dispatch('loadBiblabels')
  store.dispatch('loadDictionaries')
}

export default store
