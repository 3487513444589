import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  issuetype: null,
  issuetypes: []
}

const mutations = {
  setIssuetype(state, issuetype){
    state.issuetype = issuetype
  },
  setIssuetypes(state, issuetypes){
    state.issuetypes = issuetypes
  }
}

const getters = {
}

const actions = {
  loadIssuetype({ commit }, issuetypeId){
    axios
      .get(`${API_URL}/issuetype/${issuetypeId}`)
      .then(r => r.data)
      .then(issuetype => {
        commit('setIssuetype', issuetype)
      })
  },
  searchIssuetypes({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/issuetypes/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(issuetypes => {
        commit('setIssuetypes', issuetypes)
      })
  },
  saveIssuetype(_, issuetype){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/issuetype/${issuetype.id}`, issuetype)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createIssuetype(_, issuetype){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/issuetype`, issuetype)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Issue Type created`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteIssuetype(_, issuetype){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/issuetype/${issuetype.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Issue Type deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
