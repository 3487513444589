import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  protospellings: [],
  protospelling: null
}

const mutations = {
  searchProtospellings(state, protospellings){
    state.protospellings = protospellings
  },
  async setProtospelling(state, protospelling){
    if (!config.editable) {
      protospelling = await exportConvert.convert("protospelling.details", protospelling)
    }
    state.protospelling = protospelling
  }
}

const getters = {}

const actions = {
  searchProtospellings({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/protospellings/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(protospellings => {
        commit('searchProtospellings', protospellings)
      })
  },
  loadProtospelling({ commit }, options){
    let url = `${API_URL}/protospelling/${options.protospellingId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/protospelling/${options.protospellingId.slice(0, 2)}/${options.protospellingId}.json`
    }
    if (options.lemmaId) {
      url = `${API_URL}/protospelling/${options.protospellingId}/for_lemma/${options.lemmaId}`
    }
    return new Promise((resolve) => {
      axios
        .get(url)
        .then(r => r.data)
        .then(protospelling => {
          commit('setProtospelling', protospelling)
          resolve(protospelling)
        })
    })
  },
  saveProtospelling(_, protospelling){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/protospelling/${protospelling.id}`, protospelling)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
          // reject(error.response.data)
        })
    })
  },
  createProtospelling(_, protospelling){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/protospelling`, protospelling)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteProtospelling(_, protospelling){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/protospelling/${protospelling.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Protospelling deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
