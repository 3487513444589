import axios from 'axios'
import config from '@/config.js'

const API_URL = config.apiUrl

const state = {
  hccs: [],
  hcc: null,
  hccOccurrences: null,
}

const mutations = {
  setHCCs(state, hccs){
    state.hccs = hccs
  },
  setHCC(state, hcc){
    state.hcc = hcc
  },
  setHCCOccurrences(state, hccOccurrences){
    state.hccOccurrences = hccOccurrences
  },
}

const getters = {
}

const actions = {
  searchHCCs({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/hccs/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(hccs => {
        commit('setHCCs', hccs)
      })
  },
  loadHCC({ commit }, hccId){
    axios
      .get(`${API_URL}/hcc/${hccId}`)
      .then(r => r.data)
      .then(hcc => {
        commit('setHCC', hcc)
      })
  },
  loadOccurrences({ commit }, hccId){
    axios
      .get(`${API_URL}/hcc/${hccId}/occurrences`)
      .then(r => r.data)
      .then(hccOccurrences => {
        commit('setHCCOccurrences', hccOccurrences)
      })
  },
  assignTokensToHCC(_, data) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/hcc/${data.hccId}/assign_tokens`, { tokenIds: data.tokenIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  replaceHCC(_, data) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/hcc/${data.hccId}/tokens/replace`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  removeAssignedHCCs(_, data) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/hcc/unassign_tokens`, { tokenIds: data.tokenIds, hccIds: data.hccIds })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  saveHCC(_, data) {
    let url = data.id ? `${API_URL}/hcc/${data.id}` : `${API_URL}/hcc`
    return new Promise((resolve) => {
      axios
        .post(url, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteHCC(_, hccId) {
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/hcc/${hccId}`)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  checkHCCExists(_, data) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/hcc/exists`, data)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  calculateStats(_, data) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/hcc/hcc_stats`, data, { responseType: 'blob' })
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
