/* eslint-disable */
import axios from 'axios'
import config from '@/config.js'
import exportConfig from "@/store/export.config.json"

const API_URL_CLIENTSIDE = config.apiUrlClientSide

function getConfig(type) {
  let retVal = []
  if (type.endsWith(".details")) {
    retVal = [
      ...exportConfig[type.split(".")[0]],
      ...exportConfig[type]
    ]
  } else {
    retVal = exportConfig[type]
  }
  // console.log("CONFIG", type, retVal)
  return retVal
}

let cache = {}

// function convertArrayToObjects(arrayData, config) {
//   if (!config) {
//     console.log("NO CONFIG", config)
//     return
//   }
//   // console.log("NEW", arr, config)
//   if (Array.isArray(arrayData) && arrayData.length == 0) {
//     return []
//   }
//   if (!arrayData) {
//     return
//   }
//   const obj = {};
//   for (const _key in config) {
//     const keyIndex = _key;
//     const key = config[keyIndex];
//     if (key.constructor == Object) {
//       const subKey = Object.keys(key)[0];
//       const subValue = Object.values(key)[0];
//       // console.log("ARRR1", subKey, subValue)
//       if (Array.isArray(subValue)) {
//         obj[subKey] = [];
//         if (subValue.length == 0) return;
//         let subConfig = getConfig(subValue[0].split(":")[1]);
//         // console.log("ARRR2", subValue, subConfig, arr, keyIndex, arr[keyIndex])
//         if (arrayData[keyIndex]) {
//           for (let subArr of arrayData[keyIndex]){
//           // arrayData[keyIndex].forEach(subArr => {
//             let tmp = [];
//             // console.log("QW", subValue[0].split(":")[0], subValue[0].split(":")[1], subArr, subConfig)
//             if (subValue[0].split(":")[0] == "load") {
//               let objectId = subArr;
//               let url = subValue[0].split(":")[1];
//               console.log("Load object", objectId, url, )
//               axios
//                 .get(`${API_URL_CLIENTSIDE}/${url}/${objectId}.json`)
//                 .then(r => r.data)
//                 .then(objectData => {
//                   tmp = convertArrayToObjects(objectData, subConfig);
//                   console.log("Load object -- 1", objectData, tmp)
//                   obj[subKey].push(tmp);
//                 })

//               // let rest = async () => {
//               //   const response = await axios.get(
//               //     `${API_URL_CLIENTSIDE}/${url}/${objectId}.json`
//               //   );
//               //   return response.data;
//               // };
//               // rest().then(x => {
//               //   tmp = convertArrayToObjects(x, subConfig);
//               //   obj[subKey].push(tmp);
//               // })
//             }
//             tmp = convertArrayToObjects(subArr, subConfig);
//             obj[subKey].push(tmp);
//           }
//         }
//       }
//       else {
//         if (subValue.constructor == Object) {
//           obj[subKey] = {}
//           if (arrayData[keyIndex]) {
//             Object.keys(subValue).forEach((_subKey, _index) => {
//               obj[subKey][_subKey] = {};
//               subValue[_subKey].forEach((_subKey2, _index2) => {
//                 obj[subKey][_subKey][_subKey2] = arrayData[keyIndex][_index][_index2];
//               })
//             })
//           }
//         }
//         else if (subValue.startsWith("object:")) {
//           obj[subKey] = convertArrayToObjects(arrayData[keyIndex], getConfig(subValue.split(":")[1]));
//         } else {
//           obj[subKey] = 3111;
//         }
//       }
//     } else {
//       obj[keyIndex] = arrayData[keyIndex];
//     }
//   }
//   // console.log(obj)
//   return obj;
// }

const objectWithSubfolders = [
  'edition', 'authorhist', 'author', 'manuscript', 'annotator',
  'metatext', 'personhist', 'review', 'scriptorium', 'study', 'text',
  'token', 'lemma', 'occurrence'
]

function getObjectURL(objectType, objectId) {
  let subfolder = objectWithSubfolders.includes(objectType) ? `/${objectId.slice(0, 2)}` : ""
  return`${API_URL_CLIENTSIDE}/${objectType}${subfolder}/${objectId}.json`
}

async function convertArrayToObjects(arrayData, config) {
  if (!config) {
    // console.log("NO CONFIG", config)
    return
  }
  if (Array.isArray(arrayData) && arrayData.length == 0) {
    return []
  }
  if (!arrayData) {
    return
  }
  // console.log("R", arrayData, config)
  let dataObject = {};
  // console.log("config", config)
  for (const keyIndex in config) {
    const key = config[keyIndex];
    if (key.constructor == Object) {
      // We should have always just one key
      // E.g. 1. "manuscript": [
      //   {"edition": "object:edition"}
      // ]
      // subConfigKey = "edition"
      // subConfigValue = "object:edition"
      // E.g. 2. "manuscript": [
      //   {"editions": ["object:edition", "additionalValue"]}
      // ]
      // subConfigKey = "editions"
      // subConfigValue = ["object:edition", "additionalValue"]
      let subConfigKey = Object.keys(key)[0]
      let subConfigValue = Object.values(key)[0]
      // console.log("RT", key, subConfigKey, subConfigValue)
      // console.log("A", key, keyIndex, subConfigKey, subConfigValue)
      if (Array.isArray(subConfigValue)) {
        dataObject[subConfigKey] = []
        if (subConfigValue.length == 0) {
          continue
        }
        // console.log("AAA", subConfigValue[0].split(":").at(-1), subConfigValue)
        let subConfig = getConfig(subConfigValue[0].split(":").at(-1))
        if (arrayData[keyIndex]) {
          for (let [index, subDataArray] of arrayData[keyIndex].entries()) {
            let subDataArrayTmp = subDataArray;
            let tmpSubConfig = subConfig;

            // console.log("ENT", subConfigValue, subDataArray, subConfig)

            if (subConfigValue[0] && subConfigValue[0].split(":")[0] == "load") {
              let objectId;
              let url = subConfigValue[0].split(":")[1]
              // console.log("LOAD1", url, subDataArrayTmp)
              if (Array.isArray(subDataArrayTmp)) {
                objectId = subDataArray[0]
                subDataArrayTmp = await axios
                  .get(getObjectURL(url, objectId))
                  .then(r => r.data)
                subDataArrayTmp = subDataArrayTmp.concat(subDataArray.slice(1));
                tmpSubConfig = [...subConfig, ...subConfigValue.slice(1)]
                // console.log("LOAD2", url, subDataArrayTmp, tmpSubConfig)
              }
              else {
                objectId = subDataArray
                subDataArrayTmp = await axios
                  .get(getObjectURL(url, objectId))
                  .then(r => r.data)
              }
            }
            // console.log("AAAA", subConfigKey, subConfig)
            dataObject[subConfigKey].push(
              await convertArrayToObjects(subDataArrayTmp, tmpSubConfig)
            )
          }
        }
      }
      else {
        if (subConfigValue.constructor == Object) {
          // Special case, see text manuscriptRanges
          dataObject[subConfigKey] = {}
          if (arrayData[keyIndex]) {
            Object.keys(subConfigValue).forEach((_subConfigKey, _index) => {
              dataObject[subConfigKey][_subConfigKey] = {}
              subConfigValue[_subConfigKey].forEach((_subConfigKey2, _index2) => {
                dataObject[subConfigKey][_subConfigKey][_subConfigKey2] = arrayData[keyIndex][_index][_index2]
              })
            })
          }
        }
        else if (subConfigValue.startsWith("object:")) {
          dataObject[subConfigKey] = await convertArrayToObjects(arrayData[keyIndex], getConfig(subConfigValue.split(":")[1]))
        }
      }
    }
    else if (key.startsWith("load:")) {
      // Special case token
      // E.g. tokens: ["load:token:token"]
      // console.log("A", key, arrayData)
      let url = key.split(":")[1]
      let objectId = arrayData[keyIndex]
      let cacheKey = `${url}:${objectId}`
      if (cacheKey in cache) {
        dataObject = cache[cacheKey]
        // console.log("CACHE")
      }
      else {
        let subConfig = getConfig(key.split(":")[2])
        let subDataArrayTmp = await axios
          .get(getObjectURL(url, objectId))
          .then(r => r.data)
        dataObject = {
          ...dataObject,
          ...await convertArrayToObjects(subDataArrayTmp, subConfig)
        }
        cache[cacheKey] = dataObject
      }
    }
    else {
      dataObject[key] = arrayData[keyIndex];
    }
  }
  return dataObject;
}

function convert(type, data) {
  return convertArrayToObjects(data, getConfig(type))
}

export default {
  convert
}
