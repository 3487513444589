import axios from 'axios'
import config from '@/config.js'
import exportConvert from '@/store/export.convert.js'

const API_URL = config.apiUrl
const API_URL_CLIENTSIDE = config.apiUrlClientSide

const state = {
  category: null,
  categories: [],
}

const mutations = {
  setCategories(state, categories){
    state.categories = categories
  },
  async setCategory(state, category){
    if (!config.editable) {
      category = await exportConvert.convert("category.details", category)
    }
    state.category = category
  }
}

const getters = {
}

const actions = {
  searchCategories({ commit }, options){
    let query = options.query
    let exclude = (options.exclude || []).join(',')
    axios
      .get(`${API_URL}/categories/autocomplete?query=${query}&exclude=${exclude}`)
      .then(r => r.data)
      .then(categories => {
        commit('setCategories', categories)
      })
  },
  loadCategory({ commit }, categoryId){
    let url = `${API_URL}/category/${categoryId}`
    if (!config.editable) {
      url = `${API_URL_CLIENTSIDE}/category/${categoryId.slice(0, 2)}/${categoryId}.json`
    }
    axios
      .get(url)
      .then(r => r.data)
      .then(category => {
        commit('setCategory', category)
      })
  },
  loadCategories({ commit }){
    axios
      .get(`${API_URL}/categories/all`)
      .then(r => r.data)
      .then(categories => {
        commit('setCategories', categories)
      })
  },
  saveCategory(_, category){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/category/${category.id}`, category)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  createCategory(_, category){
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/category`, category)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  },
  deleteCategory(_, category){
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/category/${category.id}`)
        .then(response => {
          this.dispatch('addNotification', {
            type: 'success',
            text: `Category deleted`
          })
          resolve(response)
        })
        .catch(() => {
          this.dispatch('addNotification', {
            type: 'danger',
            text: `Something went wrong`
          })
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
